import React, { useState, useEffect } from "react";
import "./static/register.css";
import { Link } from "react-router-dom";
import { signup } from "./helpers/signup";

export default function Signup(props) {
  const [uname, updateUname] = useState("");
  const [email, updateEmail] = useState("");
  const [pwd, updatePwd] = useState("");
  const [cpwd, updateCpwd] = useState("");

  useEffect(() => {
    if (localStorage.getItem("email") == null) {
      document.getElementById("logout").style.display = "none";
      document.getElementById("pdf").style.display = "none";
      document.getElementById("updf").style.display = "none";

      document.getElementById("color").style.display = "none";
      document.getElementById("login").style.display = "block";
      document.getElementById("signup").style.display = "block";
      document.getElementById("home").style.display = "block";
    } else {
      document.getElementById("color").style.display = "block";
      document.getElementById("login").style.display = "none";
      document.getElementById("signup").style.display = "none";
      document.getElementById("updf").style.display = "none";

      document.getElementById("logout").style.display = "block";
      document.getElementById("pdf").style.display = "block";
      document.getElementById("home").style.display = "none";
    }
  });

  return (
    <div className="mt" style={{ backgroundRepeat: "round", marginTop: "6em" }}>
      <div className="container">
        <div className="row">
          <div className="col-md-4"></div>
          <div className="col-md-4 box">
            <h3 className="text-center">Register</h3>
            <form id="frm">
              <div className="form-group" style={{ margin: "0" }}>
                <label>Username</label>
                <input
                  type="text"
                  onKeyUp={(e) => {
                    updateUname(e.target.value);
                  }}
                  className="form-control"
                  placeholder="Enter Username"
                ></input>
              </div>
              <div className="form-group" style={{ margin: "0" }}>
                <label>Email</label>
                <input
                  type="text"
                  onKeyUp={(e) => {
                    updateEmail(e.target.value);
                  }}
                  className="form-control"
                  placeholder="Enter Email"
                ></input>
              </div>

              <div className="form-group" style={{ margin: "0" }}>
                <label>Password</label>
                <input
                  type="password"
                  onKeyUp={(e) => {
                    updatePwd(e.target.value);
                  }}
                  className="form-control"
                  placeholder="Enter  Password"
                ></input>
              </div>
              <div className="form-group" style={{ marginTop: "0" }}>
                <label>Confirm Password</label>
                <input
                  type="password"
                  onKeyUp={(e) => {
                    updateCpwd(e.target.value);
                  }}
                  className="form-control"
                  placeholder="Enter Confirm Password"
                ></input>
                <span id="sp" style={{ color: "red", display: "none" }}>
                  Password is not Matching
                </span>
              </div>
              <div
                style={{
                  marginTop: "1em",
                  marginLeft: ".2em",
                  display: "flex",
                }}
              >
                {" "}
                <input
                  type="checkbox"
                  style={{ display: "flex", marginBottom: "2.3em" }}
                />
                <p style={{ marginLeft: ".4em" }}>
                  {" "}
                  By signing up you agree to our{" "}
                  <Link to="/Termsofservice" id="term">
                    Terms of Service
                  </Link>{" "}
                  and our{" "}
                  <Link to="/Privacy" id="privacy">
                    Privacy policy.
                  </Link>
                </p>
              </div>

              <button
                type="submit"
                onClick={(e) => signup(e, uname, email, pwd, cpwd, props)}
                class="button button-contactForm btn_1"
                style={{ padding: "12px", width: "100%" }}
              >
                Register
              </button>
              <div style={{ textAlign: "center", marginTop: "1em" }}>
                Already have an account?&nbsp; <Link to="/login">Sign in</Link>
              </div>
            </form>
            <br />
          </div>
          <div className="col-md-1"></div>
        </div>
      </div>
    </div>
  );
}
