import React from "react";
import axios from "axios";
import "./static/index.css";
import Rater from "react-rater";
import $ from "jquery";
import "react-rater/lib/react-rater.css";
import {
  Spinner,
  Alert,
  Container,
  Row,
  Col,
  Modal,
  Button,
  Form,
} from "react-bootstrap";
import PropTypes from "prop-types";
import "./static/material.css";
import "../node_modules/@syncfusion/ej2-popups/styles/material.css";
import "../node_modules/@syncfusion/ej2-react-calendars/styles/material.css";
import ClipLoader from "react-spinners/ClipLoader";
import AvatarEditor from "react-avatar-editor";
// import { Form } from "react-router-dom";
import withRouter from "./withRouter";
import html2pdf from "html2pdf.js";
import { ToastComponent } from "@syncfusion/ej2-react-notifications";
import {
  SliderComponent,
  ColorPickerComponent,
} from "@syncfusion/ej2-react-inputs";
import {
  DateRangePickerComponent,
  DatePickerComponent,
} from "@syncfusion/ej2-react-calendars";
import MyTimeline from "./components/MyTimeline";
import "./react-inputs/material.css";
import "./react-inputs/quick-modal.min.css";
import { API_URL } from "./constants";

const NewStar = ({ willBeActive, isActive }) => {
  const color = isActive ? "#009688" : willBeActive ? "#009688" : "#E3E3E3";
  return (
    <i
      className="fa fa-star"
      style={{ fontSize: 14, color: color }}
      aria-hidden="true"
    />
  );
};

NewStar.propTypes = {
  willBeActive: PropTypes.bool,
  isActive: PropTypes.bool,
};
class SavedResume extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isDraft: false,
      isDraftMode: false,
      auth: localStorage.getItem("email"),
      resumeData: null,
      edit_id: null,
      loading: true,
      error: null,
      // Initialize state variables based on the JSON structure
      name: ["", "", ""], // [First Name, Last Name, Role]
      personal_details: ["", "", "", "", ""], // [Email, Phone, GitHub, Location, LinkedIn]
      title: [
        "SUMMARY",
        "EDUCATION",
        "CERTIFICATION",
        "PROFESSIONAL SKILLS",
        "WORK EXPERIENCE",
        "PROJECTS",
        "LANGUAGE",
      ],
      summary: "",
      education_degree: [], // ["BE", "ME", ...]
      education_location: [], // ["CHENNAI", "MADURAI", ...]
      education_university: [], // ["SMHSS", "SMHSS", ...]
      education_date: [], // ["Nov-2024", "Nov-2024", ...]
      education_cgpa: [],
      workexpr_companylocation: [""],
      workexpr_companyname: [""],
      workexpr_date: [""],
      workexpr_role: [""],
      zoom: 1,
      workexpr_summary: [""],
      certifications: [], // Initialize as empty array if no data
      skill: [], // initialize with data from API
      skill_percent: [], // initialize with data from API
      lang_name: [],
      lang_level: [],
      lang_rating: [],
      project_date: [""],
      project_name: [""],
      // project_sum_title: [""],
      project_summary: [""],
      myTimeChartInformation: [
        {
          proportion: 50,
          skill: "",
        },
      ],
      color: "#000", // default color
      isModalOpen: false,
      isModalOpenF: false,
      resumeTitle: "",
      loadingSave: [false, "none"], // [isLoading, displayStyle]
      dataLoadingComplete: false, // Assuming this flag exists
      file: [],
      image: "",
    };

    // Bind methods
    this.handleFirstNameChange = this.handleFirstNameChange.bind(this);
    this.handleLastNameChange = this.handleLastNameChange.bind(this);
    this.handleRoleChange = this.handleRoleChange.bind(this);
    this.insertdb = this.insertdb.bind(this);
    this.handleTitleChange = this.handleTitleChange.bind(this);
    this.handleGeneratePDF = this.handleGeneratePDF.bind(this);
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.tooltip = { placement: "Before", isVisible: true, showOn: "Active" };
    this.ticks = { placement: "Before", largeStep: 0 };
    this.addlang = this.addlang.bind(this);
    this.remlang = this.remlang.bind(this);
    this.langnameChange = this.langnameChange.bind(this);
    this.handleRate = this.handleRate.bind(this);
    this.addwork = this.addwork.bind(this);
    this.remwork = this.remwork.bind(this);
    this.addProject = this.addProject.bind(this);
    this.removeProject = this.removeProject.bind(this);
    this.handleProjectNameChange = this.handleProjectNameChange.bind(this);
    this.handleProjectDateChange = this.handleProjectDateChange.bind(this);
    this.handleProjectSumTitleChange =
      this.handleProjectSumTitleChange.bind(this);
    this.handleProjectSummaryChange =
      this.handleProjectSummaryChange.bind(this);
    this.generatePDF = this.generatePDF.bind(this);
  }

  componentWillUnmount(e, event) {
    this.insertdb(this.state);
  }
  componentDidMount() {
    this.fetchCreateResumeData();

    const { id } = this.props.params; // Access params directly
    if (id) {
      this.fetchResumeData(id);
      this.setState({ edit_id: id });
    } else {
      this.setState({ error: "No resume ID provided.", loading: false });
    }
  }

  // componentDidMount() {
  //   this.fetchCreateResumeData();
  // }

  fetchCreateResumeData = () => {
    const auth1 = this.state.auth;
    if (auth1 === null || auth1 === "undefined") {
      // Handle unauthenticated state
      // Example:
      document.getElementById("login").style.display = "block";
      document.getElementById("signup").style.display = "block";
      // ... other DOM manipulations

      if (localStorage.getItem("login") === null) {
        localStorage.setItem("login", "yes");
        window.location.href = "/";
      } else {
        localStorage.removeItem("login");
      }
    } else {
      // Handle authenticated state
      // Example:
      document.getElementById("color").style.display = "block";
      document.getElementById("signup").style.display = "none";
      document.getElementById("logout").style.display = "block";
      // ... other DOM manipulations

      axios
        .get(`https://www.flexcvpro.com/sign.php?resume1=${auth1}`)
        .then((res) => res.data)
        .then((data) => {
          if (data[0] !== null) {
            // Parse and set state with API data
            this.setState(
              {
                trainings: data[0].trainings.split(","),
                placeholder: data[0].placeholder.split(","),
                percnt: data[0].skill.split(","),
                progress: data[0].percent.split(","),
                projectname: data[0].projectname.split(","),
                projectsum: data[0].projectsum.split(","),
                pshorttitle: data[0].projectshorttitle.split(","),
                projectdate: data[0].projectdate.split(","),
                workexpr: data[0].role.split(","),
                workexpr1: data[0].cname.split(","),
                workexpr2: data[0].clocation.split(","),
                workexpr4: data[0].workdate.split(","),
                workexpr5: data[0].educationsum.split(","),
                education: data[0].eduname1.split(","),
                education1: data[0].eduname2.split(","),
                education2: data[0].edudate.split(","),
                education4: data[0].eduloc.split(","),
                education5: data[0].educgpa.split(","),
                summary: data[0].summary.split(","),
                name: [data[0].fname, data[0].lname, data[0].roles],
                socialmedia: [
                  data[0].email,
                  data[0].phone,
                  data[0].website,
                  data[0].address,
                  data[0].link,
                ],
                title: data[0].title.split(","),
                langname: data[0].langname.split(","),
                rating: data[0].rating.split(","),
                to: data[0].level.split(","),
                color: data[0].color,
                myTimeChartInformation: JSON.parse(
                  data[0].myTimeChartInformation
                ) || [
                  { proportion: 1, skill: "" },
                  { proportion: 1, skill: "" },
                ],
                dataLoadingComplete: true,
              },
              () => {
                // Additional actions post state update
                this.dataLoadingComplete = true;

                if (this.requestedAction === "generatePDF") {
                  if (this.state.resumeTitle) {
                    this.generatePDF(this.state.resumeTitle);
                  } else {
                    console.warn("Resume title is not set.");
                  }
                }
              }
            );
          } else {
            // Handle no data scenario
            this.setState({
              trainings: [""],
              placeholder: ["Certifications"],
              title: [
                "SUMMARY",
                "EDUCATION",
                "CERTIFICATION",
                "PROFESSIONAL SKILLS",
                "Work Experience",
                "PROJECTS",
                "LANGUAGE",
              ],
              dataLoadingComplete: true,
            });
          }
        })
        .catch((err) => {
          console.error(err);
          // Handle error scenario
          this.setState({ dataLoadingComplete: true });
        });
    }
  };

  // Function to save data to localStorage
  saveToLocalStorage(state) {
    localStorage.setItem("trainings", state.trainings);
    localStorage.setItem("percnt", state.percnt);
    localStorage.setItem("progress", state.progress);
    localStorage.setItem("projectname", state.projectname);
    localStorage.setItem("projectsum", state.projectsum);
    localStorage.setItem("pshorttitle", state.pshorttitle);
    localStorage.setItem("projectdate", state.projectdate);
    localStorage.setItem("workexpr", state.workexpr);
    localStorage.setItem("workexpr1", state.workexpr1);
    localStorage.setItem("workexpr2", state.workexpr2);
    localStorage.setItem("workexpr4", state.workexpr4);
    localStorage.setItem("workexpr5", state.workexpr5);
    localStorage.setItem("education", state.education);
    localStorage.setItem("education1", state.education1);
    localStorage.setItem("education2", state.education2);
    localStorage.setItem("education4", state.education4);
    localStorage.setItem("education5", state.education5);
    localStorage.setItem("summary", state.summary);
    localStorage.setItem("name", state.name);
    localStorage.setItem("socialmedia", state.socialmedia);
    localStorage.setItem("title", state.title);
    localStorage.setItem("langname", state.langname);
    localStorage.setItem("rating", state.rating);
    localStorage.setItem("to", state.to);
    localStorage.setItem("color", state.color);
    localStorage.setItem("img", this.fnm || ""); // Ensure this.fnm is defined
    localStorage.setItem(
      "myTimeChartInformation",
      JSON.stringify(state.myTimeChartInformation)
    );
    localStorage.setItem("store", "store");
  }

  // Function to send data to the create resume API
  sendToCreateResumeAPI(state, resumeTitle) {
    if (this.state.isSaving) return; // Prevent duplicate API calls

    this.setState({ loading: [true, "block"], isSaving: true });

    axios
      .post("https://www.flexcvpro.com/createresume.php", {
        auth: state.auth,
        name: state.name,
        personal_details: state.personal_details,
        summary: state.summary,
        certifications: state.certifications,
        project_name: state.project_name,
        project_date: state.project_date,
        project_summary: state.project_summary,
        // project_sum_title: state.project_sum_title,
        skill: state.skill,
        skill_percent: state.skill_percent,
        workexpr_role: state.workexpr_role,
        workexpr_companyname: state.workexpr_companyname,
        workexpr_companylocation: state.workexpr_companylocation,
        workexpr_date: state.workexpr_date,
        workexpr_summary: state.workexpr_summary,
        education_degree: state.education_degree,
        education_university: state.education_university,
        education_date: state.education_date,
        education_location: state.education_location,
        education_cgpa: state.education_cgpa,
        title: state.title,
        lang_name: state.lang_name,
        lang_rating: state.lang_rating,
        lang_level: state.lang_level,
        color: state.color,
        myTimeChartInformation: state.myTimeChartInformation,
        resume_title: state.resumeTitle,
        resume_id: state.resume_id,
      })
      .then((response) => {
        console.log("Resume created successfully:", response);
        // Handle success (e.g., show a success message or redirect)
        this.setState({ loading: [false, "none"], isSaving: false });
      })
      .catch((error) => {
        console.error("Error creating resume:", error);
        // Handle error (e.g., show an error message)
        this.setState({ loading: [false, "none"], isSaving: false });
      });
  }

  // Refactored insertdb function
  insertdb(state, forceSave = false, resumeTitle = "") {
    if (localStorage.getItem("email") === null) {
      // User is not authenticated; save to localStorage only
      this.saveToLocalStorage(state);
    } else {
      if (this.state.dataLoadingComplete && forceSave && !this.state.isSaving) {
        // User is authenticated, data is loaded, and not currently saving
        this.sendToCreateResumeAPI(state, resumeTitle);
        this.saveToLocalStorage(state);
      } else {
        // Save to localStorage without sending to API
        this.saveToLocalStorage(state);
      }
    }
  }
  // Fetch resume data from the API
  fetchResumeData = (resumeId) => {
    axios
      .post("https://www.flexcvpro.com/resumedetail.php", {
        resume_id: resumeId,
        auth: this.state.auth,
      })
      .then((response) => {
        const data = response.data.listdetail[0];
        if (data) {
          this.populateStateWithAPIData(data);
          this.setState({ image: data.image });
        } else {
          this.setState({ error: "No resume data found.", loading: false });
        }
      })
      .catch((error) => {
        console.error("Error fetching resume details:", error);
        this.setState({
          error: "Error fetching resume details.",
          loading: false,
        });
      });
  };

  // Populate state with API data
  populateStateWithAPIData = (data) => {
    console.log("API Data:", data);

    this.setState({
      name: data.name || ["", "", ""],
      personal_details: data.personal_details || ["", "", "", "", ""], // Default to empty strings for each personal detail
      title: data.title || [],
      summary: data.summary || "",
      education_degree: data.education_degree || [],
      education_location: data.education_location || [],
      education_university: data.education_university || [],
      education_date: data.education_date || [],
      education_cgpa: data.education_cgpa || [],
      workexpr_companylocation:
        Array.isArray(data.workexpr_companylocation) &&
        data.workexpr_companylocation.length > 0
          ? data.workexpr_companylocation
          : [""],
      workexpr_companyname:
        Array.isArray(data.workexpr_companyname) &&
        data.workexpr_companyname.length > 0
          ? data.workexpr_companyname
          : [""],
      workexpr_date:
        Array.isArray(data.workexpr_date) && data.workexpr_date.length > 0
          ? data.workexpr_date
          : [""],
      workexpr_role:
        Array.isArray(data.workexpr_role) && data.workexpr_role.length > 0
          ? data.workexpr_role
          : [""],
      workexpr_summary:
        Array.isArray(data.workexpr_summary) && data.workexpr_summary.length > 0
          ? data.workexpr_summary
          : [""],
      certifications: data.certifications || [],
      skill: data.skill || [],
      skill_percent: data.skill_percent || Array(data.skill.length).fill("0%"), // Default to "0%" if not available
      lang_name: Array.isArray(data.lang_name) ? data.lang_name : [],
      lang_rating: Array.isArray(data.lang_rating) ? data.lang_rating : [],
      lang_level: Array.isArray(data.lang_level) ? data.lang_level : [],
      project_date:
        Array.isArray(data.project_date) && data.project_date.length > 0
          ? data.project_date
          : [""],
      project_name:
        Array.isArray(data.project_name) && data.project_name.length > 0
          ? data.project_name
          : [""],
      project_sum_title:
        Array.isArray(data.project_sum_title) &&
        data.project_sum_title.length > 0
          ? data.project_sum_title
          : [""],
      project_summary:
        Array.isArray(data.project_summary) && data.project_summary.length > 0
          ? data.project_summary
          : [""],
      myTimeChartInformation:
        Array.isArray(data.myTimeChartInformation) &&
        data.myTimeChartInformation.length > 0
          ? data.myTimeChartInformation
          : [
              { proportion: 1, skill: "" }, // Default entry if API data is empty
            ],
      color: data.color || "#000",
      resumeTitle: data.resume_title || "",
      loading: false,
      dataLoadingComplete: true, // Assuming this flag is used
    });
  };

  // Debounce function to limit the rate of function execution
  debounce = (func, delay) => {
    let timeoutId;
    return (...args) => {
      if (timeoutId) clearTimeout(timeoutId);
      timeoutId = setTimeout(() => func(...args), delay);
    };
  };

  // Handle First Name Change
  handleFirstNameChange = this.debounce((newFirstName) => {
    if (this.state.name[0] !== newFirstName) {
      this.setState(
        (prevState) => ({
          name: [newFirstName, prevState.name[1], prevState.name[2]],
        }),
        () => this.insertdb(this.state)
      );
    }
  }, 300);

  // Handle Last Name Change
  handleLastNameChange = this.debounce((newLastName) => {
    if (this.state.name[1] !== newLastName) {
      this.setState(
        (prevState) => ({
          name: [prevState.name[0], newLastName, prevState.name[2]],
        }),
        () => this.insertdb(this.state)
      );
    }
  }, 300);

  // Handle Role Change
  handleRoleChange = this.debounce((newRole) => {
    if (this.state.name[2] !== newRole) {
      this.setState(
        (prevState) => ({
          name: [prevState.name[0], prevState.name[1], newRole],
        }),
        () => this.insertdb(this.state)
      );
    }
  }, 300);

  email(e) {
    const state = this.state;
    state.personal_details[0] = e.target.innerHTML;
    this.setState({
      personal_details: state.personal_details,
    });
    this.insertdb(state); // Call to insert into the database or handle the updated state
  }

  phno(e) {
    const state = this.state;
    state.personal_details[1] = e.target.innerHTML;
    this.setState({
      personal_details: state.personal_details,
    });
    this.insertdb(state);
  }

  link(e) {
    const state = this.state;
    state.personal_details[2] = e.target.innerHTML;
    this.setState({
      personal_details: state.personal_details,
    });
    this.insertdb(state);

    // Logic to handle the visibility of the GitHub/Website link
    state.personal_details[2]
      ? document
          .getElementsByClassName("lik1")[0]
          .removeAttribute("data-html2canvas-ignore")
      : document
          .getElementsByClassName("lik1")[0]
          .setAttribute("data-html2canvas-ignore", "true");
  }

  add(e) {
    const state = this.state;
    state.personal_details[3] = e.target.innerHTML;
    this.setState({
      personal_details: state.personal_details,
    });
    this.insertdb(state);
  }

  linked1(e) {
    var link = document.getElementById(e.target.id).getElementsByTagName("a")[0]
      ? document.getElementById(e.target.id).getElementsByTagName("a")[0].href
      : e.target.innerHTML;
    const state = this.state;
    state.personal_details[4] = link;
    this.setState({
      personal_details: state.personal_details,
    });
    this.insertdb(state);
  }

  title(e, index) {
    const newTitle = e.target.innerHTML;
    const updatedTitle = [...this.state.title];
    updatedTitle[index] = newTitle;

    this.setState({ title: updatedTitle }, () => {
      if (index === 0) {
        // Assuming index 0 is for SUMMARY
        this.setState({ summary: this.summaryText() }); // Set the summary text
      }
      this.insertdb(this.state);
    });
  }

  summaryText() {
    return this.state.summary;
  }
  summary(e) {
    const newSummary = e.target.innerHTML;
    this.setState({ summary: newSummary }, () => this.insertdb(this.state));
  }

  addedu() {
    this.setState(
      {
        education_cgpa: [...this.state.education_cgpa, ""],
        education_date: [...this.state.education_date, ""],
        education_degree: [...this.state.education_degree, ""],
        education_location: [...this.state.education_location, ""],
        education_university: [...this.state.education_university, ""],
      },
      () => {
        this.insertdb(this.state); // Update database
      }
    );
  }

  remedu() {
    const education_cgpa = [...this.state.education_cgpa];
    const education_date = [...this.state.education_date];
    const education_degree = [...this.state.education_degree];
    const education_location = [...this.state.education_location];
    const education_university = [...this.state.education_university];

    education_cgpa.pop(); // Remove last entry
    education_date.pop();
    education_degree.pop();
    education_location.pop();
    education_university.pop();

    this.setState(
      {
        education_cgpa,
        education_date,
        education_degree,
        education_location,
        education_university,
      },
      () => {
        this.insertdb(this.state); // Update database
      }
    );
  }

  // Handle Degree Change
  education_degree_change(index, e) {
    const updatedDegrees = [...this.state.education_degree];
    updatedDegrees[index] = e.target.innerHTML.trim();
    this.setState({ education_degree: updatedDegrees }, () =>
      this.insertdb(this.state)
    );
  }

  // Handle CGPA Change
  education_cgpa_change(index, e) {
    const updatedCGPA = [...this.state.education_cgpa];
    updatedCGPA[index] = e.target.innerHTML.trim();
    this.setState({ education_cgpa: updatedCGPA }, () =>
      this.insertdb(this.state)
    );
  }

  // Handle University Change
  education_university_change(index, e) {
    const updatedUniversity = [...this.state.education_university];
    updatedUniversity[index] = e.target.innerHTML.trim();
    this.setState({ education_university: updatedUniversity }, () =>
      this.insertdb(this.state)
    );
  }

  // Handle Location Change
  education_location_change(index, e) {
    const updatedLocation = [...this.state.education_location];
    updatedLocation[index] = e.target.innerHTML.trim();
    this.setState({ education_location: updatedLocation }, () =>
      this.insertdb(this.state)
    );
  }

  // Handle Education Date Change
  education_date_change(index, e) {
    const updatedDate = [...this.state.education_date];
    updatedDate[index] = e.target.value; // Assuming e.target.value is the formatted date
    this.setState({ education_date: updatedDate }, () =>
      this.insertdb(this.state)
    );
  }

  addCertification() {
    this.setState({
      certifications: [...this.state.certifications, ""],
    });
  }

  removeCertification() {
    const certifications = this.state.certifications;
    certifications.splice(-1); // Remove last certification
    this.setState({ certifications });
  }
  handleCertificationChange(index, event) {
    const certifications = [...this.state.certifications];
    certifications[index] = event.target.innerHTML;
    this.setState({ certifications });
  }

  addpro() {
    this.setState({
      skill: [...this.state.skill, ""],
      skill_percent: [...this.state.skill_percent, "0%"],
    });
  }

  rempro() {
    this.state.skill.splice(-1);
    this.state.skill_percent.splice(-1);
    this.setState({
      skill: this.state.skill,
      skill_percent: this.state.skill_percent,
    });
  }
  updateSkill(value, e) {
    const skill = [...this.state.skill];
    skill[value] = e.target.innerHTML;
    this.setState({ skill });
  }

  // Handles skill percentage update
  updateSkillPercent(value, e) {
    const skill_percent = [...this.state.skill_percent];
    skill_percent[value] = e.text;
    this.setState({ skill_percent });
  }

  tooltipChangeHandler(args) {
    // Splitting the range values from the tooltip using space into an array.

    args.text = Number(args.text) + " %";
  }

  handleSkillPercentChange = (newValue, index) => {
    const updatedSkillPercent = [...this.state.skill_percent];
    updatedSkillPercent[index] = newValue;

    this.setState(
      {
        skill_percent: updatedSkillPercent,
      },
      () => {
        console.log(this.state.skill_percent); // Check if the value updates correctly
      }
    );
  };

  prog = (index, newValue) => {
    const updatedSkillPercent = [...this.state.skill_percent];

    // Ensure the newValue is valid before updating state
    if (newValue && newValue.value !== null) {
      updatedSkillPercent[index] = newValue.text; // Use text if it's a percentage
    } else {
      updatedSkillPercent[index] = "0%"; // Default to 0% if invalid
    }

    this.setState({ skill_percent: updatedSkillPercent });
  };

  // Add a new language entry
  addlang() {
    this.setState(
      {
        lang_name: [...this.state.lang_name, ""],
        lang_rating: [...this.state.lang_rating, "1"],
        lang_level: [...this.state.lang_level, "Beginner"],
      },
      () => {
        this.insertdb(this.state); // Optional: Update the database or localStorage
      }
    );
  }

  // Remove the last language entry
  remlang() {
    if (this.state.lang_name.length > 0) {
      this.setState(
        {
          lang_name: this.state.lang_name.slice(0, -1),
          lang_rating: this.state.lang_rating.slice(0, -1),
          lang_level: this.state.lang_level.slice(0, -1),
        },
        () => {
          this.insertdb(this.state); // Optional: Update the database or localStorage
        }
      );
    }
  }

  // Handle changes to language names
  langnameChange(key, event) {
    const newLangName = event.target.innerHTML.trim();
    const updatedLangname = [...this.state.lang_name]; // Use lang_name
    updatedLangname[key] = newLangName;
    this.setState({ langname: updatedLangname }, () =>
      this.insertdb(this.state)
    );
  }

  // Handle changes to language ratings
  handleRate(key, { rating }) {
    const rateLevels = [
      "",
      "Beginner",
      "Intermediate",
      "Advanced",
      "Proficient",
      "Native",
    ];
    const newLevel = rateLevels[rating] || "Beginner"; // Corrected from lang_rating to rating

    const updatedRating = [...this.state.lang_rating];
    const updatedTo = [...this.state.lang_level];

    updatedRating[key] = rating.toString();
    updatedTo[key] = newLevel;

    this.setState(
      {
        lang_rating: updatedRating,
        lang_level: updatedTo,
      },
      () => {
        this.insertdb(this.state);
      }
    );
  }

  addwork() {
    this.setState(
      (prevState) => ({
        workexpr_companylocation: [...prevState.workexpr_companylocation, ""],
        workexpr_companyname: [...prevState.workexpr_companyname, ""],
        workexpr_date: [...prevState.workexpr_date, ""],
        workexpr_role: [...prevState.workexpr_role, ""],
        workexpr_summary: [...prevState.workexpr_summary, ""],
      }),
      () => {
        this.insertdb(this.state); // Optional: Update the database or localStorage
      }
    );
  }

  remwork() {
    const workexpr_role = [...this.state.workexpr_role];
    const workexpr_companyname = [...this.state.workexpr_companyname];
    const workexpr_companylocation = [...this.state.workexpr_companylocation];
    const workexpr_date = [...this.state.workexpr_date];
    const workexpr_summary = [...this.state.workexpr_summary];

    workexpr_role.pop(); // Remove last entry
    workexpr_companyname.pop();
    workexpr_companylocation.pop();
    workexpr_date.pop();
    workexpr_summary.pop();

    this.setState(
      {
        workexpr_role,
        workexpr_companyname,
        workexpr_companylocation,
        workexpr_date,
        workexpr_summary,
      },
      () => {
        this.insertdb(this.state);
      }
    );
  }

  langone(key, value) {
    const updatedWorkExpr = [...this.state.workexpr_companyname]; // Example for company name
    updatedWorkExpr[key] = value.target.innerHTML;
    this.setState({ workexpr_companyname: updatedWorkExpr });
  }
  handleChangeWorkField(index, field, event) {
    const newValue = event.target.innerHTML || event.target.value;

    this.setState(
      (prevState) => ({
        [field]: prevState[field].map((item, i) =>
          i === index ? newValue : item
        ),
      }),
      () => {
        this.insertdb(this.state); // Optional: Update the database or localStorage
      }
    );
  }
  workexprDateChange(key, value) {
    const monname = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    // Get the start and end dates from the selected value
    const startMonth = monname[value.target.value[0].getMonth()];
    const startYear = value.target.value[0].getFullYear();
    const endMonth = monname[value.target.value[1].getMonth()];
    const endYear = value.target.value[1].getFullYear();

    // Format the dates as required
    const formattedDate = `${startMonth} ${startYear} - ${endMonth} ${endYear}`;

    // Create a copy of the current state
    const state = { ...this.state };

    // Update the workexpr_date array at the specified index
    state.workexpr_date[key] = formattedDate;

    // Set the new state
    this.setState(
      {
        workexpr_date: state.workexpr_date,
      },
      () => {
        // Call insertdb to persist the updated state
        this.insertdb(state);
      }
    );
  }

  // Add a new project entry
  addProject() {
    this.setState(
      (prevState) => ({
        project_date: [...prevState.project_date, ""],
        project_name: [...prevState.project_name, ""],
        project_sum_title: [...prevState.project_sum_title, ""],
        project_summary: [...prevState.project_summary, ""],
      }),
      () => {
        this.insertdb(this.state); // Persist the updated state
      }
    );
  }

  // Remove the last project entry
  removeProject() {
    this.setState(
      (prevState) => ({
        project_date: prevState.project_date.slice(0, -1),
        project_name: prevState.project_name.slice(0, -1),
        project_sum_title: prevState.project_sum_title.slice(0, -1),
        project_summary: prevState.project_summary.slice(0, -1),
      }),
      () => {
        this.insertdb(this.state); // Persist the updated state
      }
    );
  }
  // Handle changes to Project Name
  handleProjectNameChange(index, event) {
    const project_name = [...this.state.project_name];
    project_name[index] = event.target.innerHTML;
    this.setState({ project_name }, () => {
      this.insertdb(this.state, true); // Persist the updated state
      console.log(this.state, "llll");
    });
  }

  // Handle changes to Project Date using DateRangePickerComponent
  handleProjectDateChange(index, value) {
    const monname = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    const startDate = new Date(value.target.value[0]);
    const endDate = new Date(value.target.value[1]);

    const dt3 = `${monname[startDate.getMonth()]} ${startDate.getFullYear()}`;
    const dt4 = `${monname[endDate.getMonth()]} ${endDate.getFullYear()}`;

    const formattedDate = `${dt3} - ${dt4}`;

    const project_date = [...this.state.project_date];
    project_date[index] = formattedDate;

    this.setState({ project_date }, () => {
      this.insertdb(this.state); // Persist the updated state
    });
  }

  // Handle changes to Project Summary Title
  handleProjectSumTitleChange(index, event) {
    const project_sum_title = [...this.state.project_sum_title];
    project_sum_title[index] = event.target.innerHTML;
    this.setState({ project_sum_title }, () => {
      this.insertdb(this.state); // Persist the updated state
    });
  }

  // Handle changes to Project Summary
  handleProjectSummaryChange(index, event) {
    const project_summary = [...this.state.project_summary];
    project_summary[index] = event.target.innerHTML;
    this.setState({ project_summary }, () => {
      this.insertdb(this.state); // Persist the updated state
    });
  }

  addToMyTime = () => {
    this.setState((prevState) => ({
      myTimeChartInformation: [
        ...prevState.myTimeChartInformation,
        { proportion: 1, skill: "" },
      ],
    }));
  };

  removeFromMyTime = () => {
    this.setState((prevState) => {
      const myTimeChartInformation = [...prevState.myTimeChartInformation];
      myTimeChartInformation.splice(-1); // Remove last item
      return { myTimeChartInformation };
    });
  };

  onMyTimeInformationChange = (data) => {
    this.setState(
      {
        myTimeChartInformation: data,
      },
      () => {
        if (this.state.dataLoadingComplete) {
          // Ensure data is loaded before persisting
          this.insertdb(this.state);
        }
      }
    );
  };

  toggle(e, k, n) {
    $("#" + k + e).toggle(1000, () => {
      document.getElementById(k + e).style.display === "none"
        ? $("#add" + e).removeClass("fa fa-minus-circle")
        : $("#add" + e).removeClass("fa fa-plus-circle");

      document.getElementById(k + e).style.display === "none"
        ? $("#add" + e).addClass("fa fa-plus-circle")
        : $("#add" + e).addClass("fa fa-minus-circle");
    });
    $("#label" + e).toggle(1000, () => {
      document.getElementById(k + e).style.display === "none"
        ? $("#tridiv" + e).css("border", "none")
        : $("#tridiv" + e).css("border", "");
      document.getElementById(k + e).style.display === "none"
        ? $("#opt" + e).css("display", "none")
        : $("#opt" + e).css("displat", "block");
    });
  }
  getOffSetTopFromRoot(child) {
    var offset = $(child).offset().top;
    return offset - $("#container1").offset().top;
  }
  generatePDF(resumeTitle) {
    if (localStorage.getItem("email") === null) {
      this.props.history.push("/login");
      return;
    }
    this.insertdb(this.state, true, resumeTitle);
    // this.insertdb(this.state, true);
    var adjustedElements = [];
    var pageHeight = 1120;
    $(".cl, .mytime-module").each(
      function (index, element) {
        if (!$(element).hasClass("row")) {
          var elementStartPosition = this.getOffSetTopFromRoot(element);
          let currentPage = Math.floor(elementStartPosition / pageHeight);
          let borderOffset = pageHeight + currentPage * pageHeight;
          let elementEndPosition =
            elementStartPosition + $(element).innerHeight();
          if (
            (elementStartPosition < borderOffset &&
              elementEndPosition > borderOffset) ||
            (elementStartPosition < borderOffset &&
              borderOffset - elementStartPosition < 20)
          ) {
            $(element).css(
              "margin-top",
              borderOffset - elementStartPosition + 50 + "px"
            );
            adjustedElements.push(element);
          }
        }
      }.bind(this)
    );
    var element = document.getElementById("container1");
    var opt = {
      margin: 0,
      filename: `${resumeTitle}.pdf`,
      image: { type: "jpeg", quality: 0.98 },
      jsPDF: { unit: "in" },
      html2canvas: { scale: 2, scrollY: 0 },
      pagebreak: { mode: "css" },
    };
    $(".e-icons, .fa-map-marker").each(function () {
      $(this).hide();
    });
    $(".location-name").each(function () {
      $(this).css({ "margin-left": "-15px" });
    });

    $(".edu-dates").each(function () {
      if (!$(this).val().length) {
        $(this).hide();
      }
    });

    // New Promise-based usage:
    html2pdf(element, opt).then(function (response) {
      const generatePDFButton = document.getElementById("generatePDFButton");

      // Check if the element exists before scrolling
      if (generatePDFButton) {
        generatePDFButton.scrollIntoView();
      }

      $(".e-icons, .fa-map-marker, .edu-dates").each(function () {
        $(this).show();
      });

      $(".location-name").each(function () {
        $(this).css({ "margin-left": "0px" });
      });

      adjustedElements.forEach(function (element) {
        $(element).css("margin-top", "0px");
      });
    });
  }
  // Handle Resume Title Change
  handleTitleChange = (e) => {
    this.setState({ resumeTitle: e.target.value });
  };

  // Open Modal
  openModal = (isDraft) => {
    this.setState({ isModalOpen: true, isDraft });
  };

  // Close Modal
  closeModal = () => {
    this.setState({ isModalOpen: false });
  };
  openModalF = () => {
    console.log("Open Modal Clicked");
    this.setState({ isModalOpenF: true });
  };

  // Close Modal
  closeModalF = () => {
    this.setState({ isModalOpenF: false });
  };
  // Open Modal for Generate PDF
  openModalForPDF = () => {
    this.setState({ isModalOpen: true, isDraftMode: false });
  };

  // Open Modal for Save as Draft
  openModalForDraft = () => {
    this.setState({ isModalOpen: true, isDraftMode: true });
  };

  // Handle Generate PDF
  handleGeneratePDF = () => {
    const { resumeTitle } = this.state;

    if (resumeTitle.trim() === "") {
      alert("Please enter a resume title.");
      return;
    }

    // Set the draft flag to false
    this.setState({ isDraft: false }, () => {
      // Close the modal
      this.closeModal();

      // Save data to the server and then generate PDF
      this.insertdb(this.state, true); // Save to server
      this.generatePDF(resumeTitle); // Generate PDF
    });
  };

  handleSaveAsDraft = () => {
    const { resumeTitle } = this.state;

    if (resumeTitle.trim() === "") {
      alert("Please enter a resume title.");
      return;
    }

    // Set the draft flag to true
    this.setState({ isDraft: true }, () => {
      // Close the modal
      this.closeModal();

      // Save data to the server as a draft
      this.insertdb(this.state, true); // Only save data, don't generate PDF
    });
  };

  // Insert or Update the resume data
  insertdb(state, forceSave = false) {
    const email = localStorage.getItem("email");
    const { edit_id } = this.state;
    if (email === null) {
      // Save to localStorage
      const keysToSave = [
        "trainings",
        "percnt",
        "progress",
        "projectname",
        "projectsum",
        "pshorttitle",
        "projectdate",
        "workexpr",
        "workexpr1",
        "workexpr2",
        "workexpr4",
        "workexpr5",
        "education",
        "education1",
        "education2",
        "education4",
        "education5",
        "summary",
        "name",
        "socialmedia",
        "title",
        "langname",
        "rating",
        "to",
        "color",
        "img",
        "myTimeChartInformation",
      ];

      keysToSave.forEach((key) => {
        if (Array.isArray(state[key])) {
          localStorage.setItem(key, state[key].join(","));
        } else if (typeof state[key] === "object") {
          localStorage.setItem(key, JSON.stringify(state[key]));
        } else {
          localStorage.setItem(key, state[key]);
        }
      });

      localStorage.setItem("store", "store");
    } else {
      if (this.state.dataLoadingComplete && forceSave) {
        this.setState({ loadingSave: [true, "block"] });

        const payload = {
          auth: state.auth,
          name: state.name,
          personal_details: state.personal_details,
          summary: state.summary,
          certifications: state.certifications,
          project_name: state.project_name,
          project_date: state.project_date,
          project_summary: state.project_summary,
          project_sum_title: state.project_sum_title,
          skill: state.skill,
          skill_percent: state.skill_percent,
          workexpr_role: state.workexpr_role,
          workexpr_companyname: state.workexpr_companyname,
          workexpr_companylocation: state.workexpr_companylocation,
          workexpr_date: state.workexpr_date,
          workexpr_summary: state.workexpr_summary,
          education_degree: state.education_degree,
          education_university: state.education_university,
          education_date: state.education_date,
          education_location: state.education_location,
          education_cgpa: state.education_cgpa,
          title: state.title,
          lang_name: state.lang_name,
          lang_rating: state.lang_rating,
          lang_level: state.lang_level,
          color: state.color,
          myTimeChartInformation: state.myTimeChartInformation,
          resume_title: state.resumeTitle,
          resume_id: localStorage.getItem("template"),
          image: state.file,
          isDraft: state.isDraft,
        };

        // If edit_id exists, add it to the payload
        if (edit_id) {
          payload.edit_id = edit_id;
        }

        axios
          .post("https://www.flexcvpro.com/createresume.php", payload)
          .then((response) => {
            setTimeout(() => {
              this.setState({ loadingSave: [false, "none"] });
            }, 3000);
          })
          .catch((error) => {
            console.error("Error saving resume data:", error);
            this.setState({ loadingSave: [false, "none"] });
          });

        // Save to localStorage as well
        const keysToSave = [
          "trainings",
          "percnt",
          "progress",
          "projectname",
          "projectsum",
          "pshorttitle",
          "projectdate",
          "workexpr",
          "workexpr1",
          "workexpr2",
          "workexpr4",
          "workexpr5",
          "education",
          "education1",
          "education2",
          "education4",
          "education5",
          "summary",
          "name",
          "socialmedia",
          "title",
          "langname",
          "rating",
          "to",
          "color",
        ];

        keysToSave.forEach((key) => {
          if (Array.isArray(state[key])) {
            localStorage.setItem(key, state[key].join(","));
          } else if (typeof state[key] === "object") {
            localStorage.setItem(key, JSON.stringify(state[key]));
          } else {
            localStorage.setItem(key, state[key]);
          }
        });

        localStorage.setItem("store", "store");
      }
    }
  }

  imgupload = (event) => {
    const file = event.target.files[0];
    if (file) {
      this.setState({ file, showCrop: true });
      const reader = new FileReader();
      reader.onloadend = () => {
        this.setState({ image: reader.result });
      };
      reader.readAsDataURL(file);
    }
  };

  onCrop = () => {
    const { editor } = this.state;
    if (editor !== null) {
      const img = editor.getImageScaledToCanvas().toDataURL();
      const rect = editor.getCroppingRect();
      this.setState({
        img: {
          img,
          rect,
          scale: this.state.zoom,
          width: "150",
          height: "150",
          borderRadius: "100",
        },
        file: img,
      });

      document.getElementById("bgimagecrop").style.display = "none";
      document.getElementById("imagecropsave").style.display = "none";
      document.getElementById("cancel").style.display = "none";

      document.getElementById("bgimageview").style.visibility = "hidden";
      document.getElementById("bgimageview").style.display = "block";
      document.getElementById("ok").style.display = "block";
      document.getElementById("imgload").style.visibility = "visible";
      setTimeout(() => {
        document.getElementById("imgload").style.visibility = "hidden";
        document.getElementById("bgimageview").style.visibility = "visible";

        document.getElementById("bgimageview").style.backgroundImage =
          "url('" + img + "')";
        document.getElementById("profile").src = img;

        if (localStorage.getItem("email") === null) {
          this.fnm = img;
          localStorage.setItem("img", this.fnm);
          localStorage.setItem("store", "store");
        } else {
          localStorage.setItem("img", img);
          localStorage.setItem("store", "store");

          axios({
            method: "post",
            url: API_URL + "/image.php",
            data: { image: img, auth: this.state.auth, oldimg: this.fnm },
            config: { headers: { "content-type": "multipart/form-data" } },
          })
            .then(function (res) {
              // console.log(res)
              this.fnm = "./images/" + res.data;
            })
            .catch(function (err) {
              //  console.log(err)
            });
        }
      }, 3000);
    }
  };

  setEditorRef = (editor) => this.setState({ editor });
  zoom = (e) => {
    this.setState({ zoom: e.text.replace("%", "") });
  };
  imgupload(e) {
    var file = e.target.files[0];
    document.getElementById("bgimageview").style.display = "none";
    document.getElementById("ok").style.display = "none";
    document.getElementById("bgimagecrop").style.display = "block";
    document.getElementById("imagecropsave").style.display = "block";
    document.getElementById("cancel").style.display = "block";
    this.setState({ file: file });
  }

  render() {
    const {
      isDraftMode,
      image,
      loading,
      error,
      name,
      personal_details,
      title,
      summary,
      color,
      isModalOpen,
      resumeTitle,
      loadingSave,
      myTimeChartInformation,
    } = this.state;

    if (loading) {
      return (
        <Container className="mt-5 text-center">
          <Spinner animation="border" role="status">
            <span className="sr-only">Loading...</span>
          </Spinner>
        </Container>
      );
    }

    if (error) {
      return (
        <Container className="mt-5">
          <Alert variant="danger">{error}</Alert>
        </Container>
      );
    }

    return (
      <div className="mt">
        {/* Your existing modal and other components */}
        {/* <div className="e-circle-wrap" id="c1">
          <ColorPickerComponent
            id="circle-palette"
            mode="Palette"
            modeSwitcher={false}
            inline={true}
            showButtons={false}
            columns={4}
            presetColors={this.circlePaletteColors}
            beforeTileRender={this.beforeCircleTileRender.bind(this)}
            change={this.change.bind(this)}
          ></ColorPickerComponent>
        </div>
        <div className="e-circle-wrap e-custom-palette" id="f1">
          <h6
            id="applesystem"
            onClick={this.updateResumeFont.bind(this)}
            className={`font-type ${
              this.state.activeFont === "applesystem" ? "activeFont" : ""
            }`}
          >
            Apple System
          </h6>
          <h6
            id="arial"
            onClick={this.updateResumeFont.bind(this)}
            className={`font-type ${
              this.state.activeFont === "arial" ? "activeFont" : ""
            }`}
          >
            Arial
          </h6>
          <h6
            id="timesnewroman"
            onClick={this.updateResumeFont.bind(this)}
            className={`font-type ${
              this.state.activeFont === "timesnewroman" ? "activeFont" : ""
            }`}
          >
            Times New Roman
          </h6>
        </div> */}
        {this.state.isModalOpenF && (
          <Modal show={this.openModalF} onHide={this.closeModalF} centered>
            {/* <div id="my-modal" class="qm-modal"> */}
            <div
              class="qm-close-modal"
              onClick={() => {
                document.getElementById("bgimagecrop").style.display = "none";
                document.getElementById("bgimageview").style.display = "block";
              }}
            ></div>
            <h3>Photo</h3>
            <hr></hr>
            <div
              style={{
                height: "330px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                className="bg-image"
                id="bgimageview"
                style={{
                  backgroundImage: `${
                    this.state.image &&
                    this.state.image !== "data:image/png;base64,"
                      ? "url(" + this.state.image
                      : "url(/user.jpeg)"
                  }`,
                  display: "block",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    visibility: "hidden",
                  }}
                  id="imgload"
                >
                  <ClipLoader size={25} color={"#123abc"} loading={true} />
                  <h5>&nbsp; Uploading...</h5>
                </div>
              </div>
              <div id="bgimagecrop" style={{ display: "none" }}>
                <AvatarEditor
                  ref={this.setEditorRef}
                  image={this.state.file}
                  width={150}
                  height={150}
                  border={50}
                  scale={this.state.zoom}
                  borderRadius={100}
                />
                <SliderComponent
                  id={"slidercrop"}
                  step="0.01"
                  change={(e) => this.zoom(e)}
                  tooltipChange={this.tooltipChangeHandler.bind(this)}
                  min={1}
                  max={10}
                  ticks={this.ticks}
                  tooltip={this.tooltip}
                  type="MinRange"
                />
              </div>
            </div>
            <hr></hr>
            <div>
              <span style={{ display: "flex", filter: "opacity(0.5)" }}>
                Import Photo
              </span>
              <button
                className="btn btn-primary  "
                onClick={() => this.imges.click()}
              >
                From Computer
              </button>
              <input
                name="images"
                onChange={(e) => this.imgupload(e)}
                accept="image/*"
                ref={(ref) => (this.imges = ref)}
                type="file"
                style={{ display: "none" }}
              />
              <button
                className="btn btn-danger float-right cancel"
                id="cancel"
                o
                onClick={() => {
                  document.getElementById("ok").style.display = "block";
                  document.getElementById("bgimagecrop").style.display = "none";
                  document.getElementById("cancel").style.display = "none";
                  document.getElementById("imagecropsave").style.display =
                    "none";
                  document.getElementById("bgimageview").style.display =
                    "block";
                }}
                style={{ display: "none", marginLeft: "2%" }}
              >
                CANCEL
              </button>
              <button
                className="btn btn-success float-right"
                id="imagecropsave"
                style={{ display: "none" }}
                onClick={this.onCrop}
              >
                SAVE
              </button>
              <button
                className="btn btn-success float-right save"
                id="ok"
                onClick={() => {
                  document.getElementById("ok").style.display = "block";
                  document.getElementById("bgimagecrop").style.display = "none";
                  document.getElementById("cancel").style.display = "none";
                  document.getElementById("imagecropsave").style.display =
                    "none";
                  document.getElementById("bgimageview").style.display =
                    "block";
                  this.closeModalF();
                }}
              >
                OK
              </button>
            </div>
            {/* </div> */}
          </Modal>
        )}

        {/* <div
          className="loading"
          id="loading"
          style={{ display: this.state.loading[1] }}
        >
          <ClipLoader
            size={20}
            color={"#123abc"}
            loading={this.state.loading[0]}
          />
          <label>Saving...</label>
        </div>
        <ToastComponent
          ref={(toast) => {
            this.toastObj = toast;
          }}
          id="toast_type"
          position={this.position}
          close={this.onclose.bind(this)}
        ></ToastComponent> */}

        <div className="gd" id="grid2">
          <div className="container container1" id="1">
            <div
              className="row"
              id="container1"
              style={{ backgroundColor: "white" }}
            >
              <div
                className="col-md-12"
                style={{ padding: "2% 6%", backgroundColor: "#EDEDED" }}
              >
                <div className="row">
                  <div className="col-md-5">
                    <ul
                      type="none"
                      style={{ marginTop: "1rem", padding: "2% 0%" }}
                    >
                      {/* First Name */}
                      <div
                        className="fname"
                        style={{ fontWeight: "bold" }}
                        placeholder="Your"
                        suppressContentEditableWarning={true}
                        contentEditable="true"
                        onBlur={(e) =>
                          this.handleFirstNameChange(e.target.innerText.trim())
                        }
                        id="fname"
                      >
                        {name[0]}
                      </div>

                      {/* Last Name */}
                      <div
                        className="lname"
                        style={{ fontWeight: "bold" }}
                        placeholder="Name"
                        suppressContentEditableWarning={true}
                        contentEditable="true"
                        onBlur={(e) =>
                          this.handleLastNameChange(e.target.innerText.trim())
                        }
                        id="lname"
                      >
                        {name[1]}
                      </div>

                      {/* Role */}
                      <div
                        className="role"
                        suppressContentEditableWarning={true}
                        contentEditable="true"
                        placeholder="SOCIAL MEDIA SPECIALIST"
                        onBlur={(e) =>
                          this.handleRoleChange(e.target.innerText.trim())
                        }
                        id="spl"
                      >
                        {name[2]}
                      </div>
                    </ul>
                  </div>

                  {/* Image sections */}

                  <div className="col-md-2" style={{ cursor: "pointer" }}>
                    <div style={{ marginTop: "1rem" }}>
                      <div class="cv-class text-center">
                        <img
                          style={{
                            cursor: "pointer",
                            borderRadius: "80px",
                            width: "10rem",
                            height: "10rem",
                          }}
                          id="profile"
                          className="img-responsive"
                          data-src="holder.js/100x100"
                          alt="CV"
                          src={
                            this.state.image &&
                            this.state.image !== "data:image/png;base64,"
                              ? this.state.image
                              : "/user.jpeg"
                          }
                          data-itemprop="image"
                        />
                        <button
                          data-html2canvas-ignore="true"
                          type="button"
                          data-modal-id="my-modal"
                          onClick={this.openModalF}
                          class="btn btn-primary btn-icon open-modal"
                        >
                          <i class="fa fa-cloud-upload btn-icon-single"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                  {/* personal_details section */}
                  <div className="col-md-5">
                    <ul type="none" style={{ width: "100%" }}>
                      {/* Email */}
                      <li id="bar">
                        <i className="fa fa-envelope i"></i>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <span
                          suppressContentEditableWarning={true}
                          contentEditable="true"
                          style={{ fontSize: "12px" }}
                          className="dflt"
                          id="email"
                          onBlur={this.email.bind(this)}
                          placeholder="p.smith@gmail.com"
                        >
                          {personal_details[0] || ""}
                        </span>
                      </li>

                      {/* Phone Number */}
                      <li id="bar">
                        <i className="fa fa-phone i"></i>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <span
                          suppressContentEditableWarning={true}
                          contentEditable="true"
                          style={{ fontSize: "12px" }}
                          id="phno"
                          onBlur={this.phno.bind(this)}
                          className="dflt"
                          placeholder="+1 367 6789 897"
                        >
                          {personal_details[1] || ""}
                        </span>
                      </li>

                      {/* GitHub/Website Link */}
                      <li
                        title="Optional"
                        id="bar"
                        className="lik1"
                        data-ignore-case="true"
                      >
                        <i className="fa fa-globe i"></i>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <span
                          suppressContentEditableWarning={true}
                          contentEditable="true"
                          style={{ fontSize: "12px" }}
                          id="link"
                          onBlur={this.link.bind(this)}
                          className="dflt"
                          placeholder="example https://github.com"
                        >
                          {personal_details[2] || ""}
                        </span>
                      </li>

                      {/* Address */}
                      <li id="bar">
                        <i className="fa fa-home i"></i>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <span
                          suppressContentEditableWarning={true}
                          contentEditable="true"
                          style={{ fontSize: "12px" }}
                          onBlur={this.add.bind(this)}
                          id="tes"
                          className="dflt pre-line"
                          placeholder="21 E 27th st, New York NY 1245, USA"
                        >
                          {personal_details[3] || ""}
                        </span>
                      </li>

                      {/* LinkedIn Profile */}
                      <li id="bar">
                        <a
                          rel="noopener noreferrer"
                          href={
                            "https://www.linkedin.com/in/" + personal_details[4]
                          }
                          target="_blank"
                        >
                          <i className="fa fa-linkedin i"></i>
                        </a>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <span
                          contentEditable="true"
                          suppressContentEditableWarning={true}
                          onBlur={this.linked1.bind(this)}
                          id="lnk"
                          style={{ fontSize: "12px" }}
                          className="dflt"
                          placeholder="Enter the LinkedIn Profile Name"
                        >
                          {personal_details[4] || ""}
                        </span>
                      </li>
                    </ul>
                  </div>

                  {/* Add other sections like personal details, education, etc. here */}
                </div>
              </div>

              {/* SUMMARY section */}

              <div className="col-md-12" style={{ padding: "2% 6%" }}>
                <div className="row">
                  <div className="col-md-12">
                    <b>
                      <span
                        className="dflt dflt1 header t pre-line"
                        suppressContentEditableWarning={false}
                        style={{
                          color: `${this.state.color}`,
                          userSelect: "none",
                        }}
                        contentEditable="false"
                        onBlur={(e) => this.title(e, 0)} // Index 0 for SUMMARY
                        id={"sumt"}
                        placeholder="SUMMARY"
                      >
                        {this.state.title[0] || "SUMMARY"}
                      </span>{" "}
                    </b>
                    <label
                      className="float-right"
                      data-html2canvas-ignore="true"
                    ></label>
                    <hr />
                    <div
                      suppressContentEditableWarning={true}
                      style={{ fontSize: "14px" }}
                      id="summary"
                      className="dflt pre-line"
                      contentEditable="true"
                      onBlur={(e) => this.summary(e)} // Handle summary input
                      placeholder="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. "
                    >
                      {this.state.summary}
                    </div>
                  </div>
                  <br />
                  <br />
                </div>
              </div>
              <div
                className="col-md-12 left"
                id="mainleft1"
                style={{ padding: "2% 6%" }}
              >
                <div className="row sortable-list  droppable-area1 connected-sortable">
                  <div className="col-md-12 eg">
                    <div className="row rowt">
                      <div className="col-md-12 cl handle">
                        <i
                          className="fa fa-graduation-cap i handle"
                          style={{
                            fontSize: "25px",
                            position: "absolute",
                            right: "95%",
                            top: "15%",
                          }}
                          aria-hidden="true"
                        ></i>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <b style={{ marginLeft: "1em" }}>
                          <span
                            className="dflt dflt1 header t pre-line"
                            suppressContentEditableWarning={true}
                            contentEditable="true"
                            onBlur={(e) => this.title(e, 1)} // Index 1 for EDUCATION
                            id={"edut"}
                            placeholder="EDUCATION"
                          >
                            {this.state.title[1] || "EDUCATION"}
                          </span>
                        </b>
                        &nbsp;&nbsp;
                        <i
                          id="ico1"
                          onClick={this.addedu.bind(this)}
                          className="fa fa-plus-circle i"
                          style={{ fontSize: "20px", cursor: "pointer" }}
                          data-html2canvas-ignore="true"
                        ></i>
                        &nbsp;&nbsp;
                        <i
                          data-html2canvas-ignore="true"
                          onClick={this.remedu.bind(this)}
                          className="fa fa-minus-circle i"
                          style={{
                            color: "#F25737",
                            fontSize: "20px",
                            cursor: "pointer",
                          }}
                          id="ico2"
                        ></i>
                        <hr />
                      </div>
                    </div>

                    <div className="row">
                      <edit id="sortable" className="col-md-12 left1">
                        {this.state.education_degree.map((degree, index) => (
                          <div
                            className="col-md-12 cl"
                            style={{ padding: "0% 0%" }}
                            key={index}
                          >
                            <div className="row">
                              <div className="col-md-8">
                                {/* Degree and Field of Study */}
                                <span
                                  className="dflt roles pre-line"
                                  style={{
                                    textTransform: "capitalize",
                                    fontSize: "14px",
                                  }}
                                  suppressContentEditableWarning={true}
                                  contentEditable="true"
                                  onBlur={(e) =>
                                    this.education_degree_change(index, e)
                                  }
                                  id={`edu${index}`}
                                  placeholder="Degree and Field of Study"
                                >
                                  {degree || ""}
                                </span>
                                <br />
                                {/* CGPA */}
                                <span
                                  className="dflt roles pre-line"
                                  style={{
                                    textTransform: "capitalize",
                                    fontSize: "14px",
                                  }}
                                  suppressContentEditableWarning={true}
                                  contentEditable="true"
                                  onBlur={(e) =>
                                    this.education_cgpa_change(index, e)
                                  }
                                  placeholder="CGPA"
                                >
                                  {this.state.education_cgpa[index] || ""}
                                </span>
                                <br />
                                {/* University */}
                                <b>
                                  <span
                                    className="dflt dflt1 pre-line"
                                    style={{ fontSize: "14px" }}
                                    suppressContentEditableWarning={true}
                                    contentEditable="true"
                                    onBlur={(e) =>
                                      this.education_university_change(index, e)
                                    }
                                    placeholder="School or University"
                                  >
                                    {this.state.education_university[index] ||
                                      ""}
                                  </span>
                                </b>

                                <div className="row">
                                  <div className="col-md-12">
                                    <i className="fa fa-map-marker i f14 map-mark pre-line"></i>
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                    {/* Location */}
                                    <span
                                      className="dflt roles pre-line location-name"
                                      style={{ fontSize: "12px" }}
                                      suppressContentEditableWarning={true}
                                      contentEditable="true"
                                      onBlur={(e) =>
                                        this.education_location_change(index, e)
                                      }
                                      placeholder="Location"
                                    >
                                      {this.state.education_location[index] ||
                                        ""}
                                    </span>
                                  </div>
                                  <div className="col-md-3">
                                    {/* Date Picker for Education Date */}
                                    <DatePickerComponent
                                      value={this.state.education_date[index]}
                                      onChange={(e) =>
                                        this.education_date_change(index, e)
                                      }
                                      format="MMM y"
                                      placeholder="MM-YYYY"
                                      allowEdit={false}
                                    />
                                    <br />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </edit>
                    </div>
                  </div>
                  <div className="col-md-12 tc" style={{ paddingTop: "1em" }}>
                    <div className="row rowt">
                      <div className="col-md-12 cl handle">
                        <i
                          style={{
                            fontSize: "25px",
                            position: "absolute",
                            right: "95%",
                            top: "15%",
                          }}
                          className="fa fa-certificate i handle pre-line"
                          aria-hidden="true"
                        ></i>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <b>
                          <span
                            className="dflt dflt1 header t"
                            style={{ color: `${this.state.color}` }}
                            contentEditable="true"
                            suppressContentEditableWarning={true}
                            id={"trit"}
                            placeholder="CERTIFICATION"
                          >
                            {this.state.title
                              ? this.state.title[2]
                              : "CERTIFICATION"}
                          </span>
                        </b>
                        &nbsp;&nbsp;
                        <i
                          style={{ fontSize: "20px", cursor: "pointer" }}
                          onClick={this.addCertification.bind(this)}
                          className="fa fa-plus-circle i"
                          data-html2canvas-ignore="true"
                        ></i>
                        &nbsp;&nbsp;
                        <i
                          onClick={this.removeCertification.bind(this)}
                          style={{
                            color: "#F25737",
                            fontSize: "20px",
                            cursor: "pointer",
                          }}
                          className="fa fa-minus-circle i"
                          data-html2canvas-ignore="true"
                        ></i>
                        <hr />
                      </div>
                    </div>

                    <div className="row">
                      <edit id="sortable1" className="col-md-12 left2">
                        {this.state.certifications.map((cert, index) => (
                          <div
                            key={index}
                            style={{ padding: "0% 0%" }}
                            className="cl"
                          >
                            <span
                              //   onClick={(e) => this.focs(e)}
                              suppressContentEditableWarning={true}
                              style={{ fontSize: "14px" }}
                              tabIndex="1"
                              id={"certification" + index}
                              contentEditable="true"
                              onBlur={this.handleCertificationChange.bind(
                                this,
                                index
                              )}
                              className="dflt roles pre-line"
                              placeholder="Certifications"
                            >
                              {cert}
                            </span>
                            <br />
                          </div>
                        ))}
                      </edit>
                    </div>
                  </div>
                  <div className="col-md-12 sk" style={{ paddingTop: "1em" }}>
                    <div className="row cl rowt">
                      <div className="col-md-12 cl handle">
                        <i
                          style={{
                            fontSize: "25px",
                            position: "absolute",
                            right: "95%",
                            top: "15%",
                          }}
                          className="fa fa-line-chart i handle"
                          aria-hidden="true"
                        ></i>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
                        <b>
                          <span
                            className="dflt dflt1 header t pre-line"
                            style={{ color: `${this.state.color}` }}
                            suppressContentEditableWarning={true}
                            contentEditable="true"
                            onBlur={(e) => this.title(e, 3)}
                            id={"proft"}
                            placeholder="PROFESSIONAL SKILLS"
                          >
                            {this.state.title
                              ? this.state.title[3]
                              : "PROFESSIONAL SKILLS"}
                          </span>
                        </b>
                        &nbsp;&nbsp;
                        <i
                          style={{ fontSize: "20px", cursor: "pointer" }}
                          onClick={this.addpro.bind(this)}
                          className="fa fa-plus-circle i"
                          id="ico5"
                          data-html2canvas-ignore="true"
                        ></i>
                        &nbsp;&nbsp;
                        <i
                          onClick={this.rempro.bind(this)}
                          style={{
                            color: "#F25737",
                            fontSize: "20px",
                            cursor: "pointer",
                          }}
                          id="ico6"
                          className="fa fa-minus-circle i"
                          data-html2canvas-ignore="true"
                        ></i>
                        <hr />
                      </div>
                    </div>

                    <div className="row cl">
                      <edit className="col-md-12 left3">
                        {this.state.skill.map((skill, index) => (
                          <div
                            className="cl"
                            style={{
                              display: "flex",
                              marginBottom: "2vh",
                              padding: "0% 0%",
                            }}
                          >
                            <div style={{ width: "40%" }}>
                              <span
                                className="dflt roles pre-line"
                                contentEditable="true"
                                suppressContentEditableWarning={true}
                                style={{ textTransform: "capitalize" }}
                                placeholder="Photoshop"
                                onBlur={(e) => this.updateSkill(index, e)}
                              >
                                {skill}
                              </span>
                            </div>
                            <div className="col-md-2">
                              <span className="badge badge-dark">
                                {this.state.skill_percent[index]}
                              </span>
                            </div>
                            <div className="col-md-6">
                              <SliderComponent
                                id={"slider" + index} // or value if needed
                                change={this.prog.bind(this, index)} // Ensure prog is bound to update the correct index
                                tooltipChange={this.tooltipChangeHandler.bind(
                                  this
                                )} // Handle tooltip change
                                value={
                                  this.state.skill_percent[index]
                                    ? parseInt(
                                        this.state.skill_percent[index].replace(
                                          "%",
                                          ""
                                        )
                                      )
                                    : 0
                                } // Convert the skill_percent string to a number for the slider
                                min={0}
                                max={100}
                                ticks={this.ticks}
                                tooltip={this.tooltip}
                                type="MinRange"
                              />
                            </div>
                          </div>
                        ))}
                      </edit>
                    </div>
                  </div>

                  {/* <div className="col-md-12 lg" style={{ paddingTop: "1em" }}>
                    <div className="row rowt cl">
                      <div className="col-md-12 cl handle">
                        <i
                          style={{
                            fontSize: "25px",
                            position: "absolute",
                            right: "95%",
                            top: "15%",
                          }}
                          className="fa fa-language i"
                          aria-hidden="true"
                        ></i>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <b>
                          <span
                            className="dflt dflt1 header t pre-line"
                            style={{ color: `${this.state.color}` }}
                            suppressContentEditableWarning={true}
                            contentEditable="true"
                            onBlur={(e) => this.title(e, 6)}
                            id={"langt"}
                            placeholder="LANGUAGE"
                          >
                            {this.state.title
                              ? this.state.title[6]
                              : "LANGUAGE"}
                          </span>
                        </b>
                        &nbsp;&nbsp;
                        <i
                          id="ico7"
                          className="fa fa-plus-circle i"
                          onClick={this.addlang.bind(this)}
                          style={{ fontSize: "20px", cursor: "pointer" }}
                          data-html2canvas-ignore="true"
                        ></i>
                        &nbsp;&nbsp;
                        <i
                          className="fa fa-minus-circle i"
                          onClick={this.remlang.bind(this)}
                          style={{
                            color: "#F25737",
                            fontSize: "20px",
                            cursor: "pointer",
                          }}
                          data-html2canvas-ignore="true"
                        ></i>
                        <hr />
                      </div>
                    </div>

                    <div className="row cl">
                      <div id="sortable6" className="col-md-12">
                        <edit
                          id="sortable6"
                          className="row left4"
                          style={{ padding: "0%", margin: "1px" }}
                        >
                          {this.state.lang_name &&
                            this.state.lang_name.length > 0 &&
                            this.state.lang_name.map((value, key4) => (
                              <div className="col-md-6" key={key4}>
                                <div className="row">
                                  <div className="col-md-6">
                                    <b>
                                      <span
                                        suppressContentEditableWarning={true}
                                        contentEditable="true"
                                        id={"langn" + key4}
                                        onBlur={this.langnameChange.bind(
                                          this,
                                          key4
                                        )}
                                        className="dflt roles pre-line"
                                        style={{
                                          textTransform: "capitalize",
                                          fontSize: "14px",
                                        }}
                                        placeholder="Language"
                                      >
                                        {this.state.lang_name[key4]}
                                      </span>
                                    </b>
                                    <br />
                                    <span
                                      className="dflt roles"
                                      style={{ fontSize: "12px" }}
                                    >
                                      {this.state.lang_level[key4]}{" "}
                                    </span>
                                  </div>
                                  <div className="col-md-6">
                                    <Rater
                                      rating={
                                        parseInt(
                                          this.state.lang_rating[key4],
                                          10
                                        ) || 1
                                      } 
                                      total={5}
                                      id={"rate" + key4}
                                      onRate={this.handleRate.bind(this, key4)}
                                    >
                                      <NewStar />
                                    </Rater>
                                  </div>
                                </div>
                              </div>
                            ))}
                        </edit>
                      </div>
                    </div>
                  </div> */}

                  <div className="col-md-12 pt" style={{ paddingTop: "1em" }}>
                    <div className="row rowt">
                      <div className="col-md-12 cl handle">
                        <i
                          aria-hidden="true"
                          className="fa fa-briefcase i handle"
                          style={{
                            fontSize: "25px",
                            position: "absolute",
                            right: "95%",
                            top: "15%",
                          }}
                        ></i>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
                        <b>
                          <span
                            className="dflt dflt1 header t pre-line"
                            contentEditable="true"
                            suppressContentEditableWarning={true}
                            onBlur={(e) => this.title(e, 4)}
                            style={{ color: `${this.state.color}` }}
                            placeholder="WORK EXPERIENCE"
                            id={"workt"}
                          >
                            {this.state.title
                              ? this.state.title[4]
                              : "WORK EXPERIENCE"}
                          </span>
                        </b>
                        &nbsp;&nbsp;{" "}
                        <i
                          className="fa fa-plus-circle i"
                          onClick={this.addwork.bind(this)}
                          style={{ fontSize: "20px", cursor: "pointer" }}
                          data-html2canvas-ignore="true"
                        ></i>
                        &nbsp;&nbsp;
                        <i
                          id="ico10"
                          data-html2canvas-ignore="true"
                          className="fa fa-minus-circle i"
                          onClick={this.remwork.bind(this)}
                          style={{
                            color: "#F25737",
                            fontSize: "20px",
                            cursor: "pointer",
                          }}
                        ></i>
                        <hr />
                      </div>
                    </div>

                    <div className="row">
                      <edit className="col-md-12 left5" id="sortable4">
                        {this.state.workexpr_companyname &&
                          this.state.workexpr_companyname.length > 0 &&
                          this.state.workexpr_companyname.map(
                            (company, index) => (
                              <div
                                className="cl"
                                style={{
                                  marginLeft: "auto",
                                  marginRight: "auto",
                                }}
                                key={index}
                              >
                                <div
                                  className="dn"
                                  style={{ padding: "0% 0%" }}
                                >
                                  {/* Role and Company Name */}
                                  <span
                                    contentEditable="true"
                                    suppressContentEditableWarning={true}
                                    onBlur={(e) =>
                                      this.handleChangeWorkField(
                                        index,
                                        "workexpr_role",
                                        e
                                      )
                                    }
                                    className="dflt roles pre-line"
                                    placeholder="Role"
                                    style={{ textTransform: "capitalize" }}
                                  >
                                    {this.state.workexpr_role[index]}
                                  </span>
                                  -
                                  <span
                                    contentEditable="true"
                                    style={{ textTransform: "capitalize" }}
                                    suppressContentEditableWarning={true}
                                    onBlur={(e) => this.langone(index, e)} // Update the specific company name
                                    className="dflt roles pre-line"
                                    placeholder="Company Name"
                                  >
                                    {this.state.workexpr_companyname[index]}
                                  </span>
                                </div>

                                <div style={{ padding: "0%" }}>
                                  {/* Company Location */}
                                  <div className="row">
                                    <div className="col-md-12 dn">
                                      <i className="fa fa-map-marker i f14 map-mark1"></i>
                                      &nbsp;&nbsp;&nbsp;&nbsp;
                                      <span
                                        style={{ fontSize: "12px" }}
                                        contentEditable="true"
                                        suppressContentEditableWarning={true}
                                        onBlur={(e) =>
                                          this.handleChangeWorkField(
                                            index,
                                            "workexpr_companylocation",
                                            e
                                          )
                                        }
                                        className="dflt roles location-name"
                                        placeholder="Location"
                                      >
                                        {
                                          this.state.workexpr_companylocation[
                                            index
                                          ]
                                        }
                                      </span>
                                    </div>
                                  </div>

                                  <div style={{ width: "25%" }}>
                                    {/* Work Date Range */}
                                    <DateRangePickerComponent
                                      start={this.start}
                                      depth={this.depth}
                                      onChange={this.workexprDateChange.bind(
                                        this,
                                        index
                                      )} // Bind the index for work experience
                                      value={this.state.workexpr_date[index]} // Use the work experience date from state
                                      format="MMMM yyyy"
                                      placeholder="MM-YYYY  MM-YYYY"
                                      allowEdit={false}
                                    ></DateRangePickerComponent>
                                  </div>

                                  {/* Work Summary */}
                                  <p
                                    contentEditable="true"
                                    style={{
                                      marginTop: "7px",
                                      textAlign: "justify",
                                      fontSize: "14px",
                                    }}
                                    suppressContentEditableWarning={true}
                                    onBlur={(e) =>
                                      this.handleChangeWorkField(
                                        index,
                                        "workexpr_summary",
                                        e
                                      )
                                    }
                                    className="dflt pre-line"
                                    placeholder="The number of lines in a paragraph depends on the size of the browser window. If you resize the browser window, the number of lines in this paragraph will change.
                This paragraph contains a lot of lines in the source code."
                                  >
                                    {this.state.workexpr_summary[index]}
                                  </p>
                                </div>
                              </div>
                            )
                          )}
                      </edit>
                    </div>
                  </div>

                  <div className="col-md-12 pn" style={{ paddingTop: "1em" }}>
                    <div className="row rowt">
                      <div className="col-md-12 cl handle">
                        <i
                          style={{
                            fontSize: "25px",
                            position: "absolute",
                            right: "95%",
                            top: "15%",
                          }}
                          className="fa fa-flask i handle"
                          aria-hidden="true"
                        ></i>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
                        <b>
                          <span
                            className="dflt dflt1 header t pre-line"
                            style={{ color: `${this.state.color}` }}
                            // onClick={(e) => this.focs(e)}
                            suppressContentEditableWarning={true}
                            contentEditable="true"
                            onBlur={(e) => this.title(e, 5)}
                            id={"projt"}
                            placeholder="PROJECTS"
                          >
                            {this.state.title
                              ? this.state.title[5]
                              : "PROJECTS"}
                          </span>
                        </b>
                        &nbsp;&nbsp;
                        <i
                          style={{ fontSize: "20px", cursor: "pointer" }}
                          onClick={this.addProject}
                          className="fa fa-plus-circle i"
                          id="ico11"
                          data-html2canvas-ignore="true"
                        ></i>
                        &nbsp;&nbsp;
                        <i
                          onClick={this.removeProject}
                          style={{
                            color: "#F25737",
                            fontSize: "20px",
                            cursor: "pointer",
                          }}
                          className="fa fa-minus-circle i"
                          id="ico12"
                          data-html2canvas-ignore="true"
                        ></i>
                        <hr />
                      </div>
                    </div>

                    <div className="row">
                      <edit id="sortable5" className="col-md-12 left6">
                        {this.state.project_name.map((project, index) => (
                          <div
                            style={{ padding: "0% 0%" }}
                            className="cl"
                            key={index}
                          >
                            {/* Project Name */}
                            <b>
                              <span
                                // onClick={(e) => this.focs(e)}
                                suppressContentEditableWarning={true}
                                contentEditable="true"
                                id={"proj" + index}
                                onBlur={(e) =>
                                  this.handleProjectNameChange(index, e)
                                }
                                className="dflt roles pre-line"
                                style={{
                                  textTransform: "capitalize",
                                  fontSize: "15px",
                                }}
                                placeholder="Project Name"
                              >
                                {this.state.project_name[index]}
                              </span>
                            </b>
                            <br />

                            {/* Project Date Range */}
                            <div style={{ width: "25%" }}>
                              <DateRangePickerComponent
                                start={this.start}
                                depth={this.depth}
                                onChange={(e) =>
                                  this.handleProjectDateChange(index, e)
                                }
                                id={"projdate" + index}
                                value={this.state.project_date[index]}
                                format="MMMM yyyy"
                                placeholder="MM-YYYY  MM-YYYY"
                                allowEdit={false}
                              />
                              <br />
                            </div>

                            {/* Project Summary Title */}
                            <b>
                              <span
                                // onClick={(e) => this.focs(e)}
                                id={"shorttitle" + index}
                                onBlur={(e) =>
                                  this.handleProjectSumTitleChange(index, e)
                                }
                                suppressContentEditableWarning={true}
                                contentEditable="false"
                                placeholder="Short Summary"
                                className="dflt dflt1 pre-line"
                                style={{
                                  textTransform: "capitalize",
                                  fontSize: "15px",
                                }}
                              >
                                {this.state.project_sum_title[index]}
                              </span>
                            </b>
                            <br />

                            {/* Project Summary */}
                            <div
                              //   onClick={(e) => this.focs(e)}
                              style={{ fontSize: "14px" }}
                              suppressContentEditableWarning={true}
                              contentEditable="true"
                              id={"shortsum" + index}
                              onBlur={(e) =>
                                this.handleProjectSummaryChange(index, e)
                              }
                              className="dflt pre-line"
                              placeholder="Project Summary"
                            >
                              {this.state.project_summary[index]}
                            </div>
                            <br />
                          </div>
                        ))}
                      </edit>
                    </div>
                  </div>
                  <div
                    className="col-md-12 lg mytime-module"
                    style={{ paddingTop: "1em" }}
                  >
                    <div className="row rowt cl">
                      <div className="col-md-12 cl handle">
                        <i
                          style={{
                            fontSize: "25px",
                            position: "absolute",
                            top: "15%",
                          }}
                          className="fa fa-briefcase i"
                          aria-hidden="true"
                        ></i>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
                        <b>
                          <span
                            className="dflt dflt1 header t pre-line"
                            style={{ color: `${this.state.color}` }}
                            id={"timeline"}
                            placeholder="MY TIMELINE"
                          >
                            MY TIME
                          </span>
                        </b>
                        &nbsp;&nbsp;
                        <i
                          style={{ fontSize: "20px", cursor: "pointer" }}
                          onClick={this.addToMyTime}
                          className="fa fa-plus-circle i"
                          id="ico7"
                        ></i>
                        &nbsp;&nbsp;
                        <i
                          onClick={this.removeFromMyTime}
                          style={{
                            color: "#F25737",
                            fontSize: "20px",
                            cursor: "pointer",
                          }}
                          id="ico8"
                          className="fa fa-minus-circle i"
                        ></i>
                        <hr />
                      </div>
                    </div>
                    <div className="row cl">
                      <MyTimeline
                        color={this.state.color}
                        myTimeChartInformation={myTimeChartInformation}
                        onChange={this.onMyTimeInformationChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Generate PDF Button */}
        <div className="d-flex flex-column pbt-10">
          <button className="pbt mb-2" onClick={this.openModalForPDF}>
            Generate PDF
          </button>

          <button className="pbt" onClick={this.openModalForDraft}>
            Save as Draft
          </button>
        </div>

        {/* Modal for Resume Title */}
        <Modal show={isModalOpen} onHide={this.closeModal} centered>
          <Modal.Header closeButton>
            <Modal.Title>Enter Resume Title</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group controlId="resumeTitle">
                <Form.Label>Resume Title</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Resume Title"
                  value={resumeTitle}
                  onChange={this.handleTitleChange}
                />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.closeModal}>
              Cancel
            </Button>

            {/* Conditionally render Save as Draft button if in draft mode */}
            {isDraftMode ? (
              <Button variant="primary" onClick={this.handleSaveAsDraft}>
                Save as Draft
              </Button>
            ) : (
              <Button variant="primary" onClick={this.handleGeneratePDF}>
                Generate PDF
              </Button>
            )}
          </Modal.Footer>
        </Modal>

        {/* Loading Indicator for Save Operation */}
        <div
          className="loading"
          id="loading"
          style={{ display: loadingSave[1] }}
        >
          <ClipLoader size={20} color={"#123abc"} loading={loadingSave[0]} />
          <label>Saving...</label>
        </div>
      </div>
    );
  }
}

SavedResume.propTypes = {
  params: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
};

export default withRouter(SavedResume);
