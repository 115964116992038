import React from 'react';
import './static/index.css';
import Routes from './router/routes';

export default function App(props){
  return(
    <div>
       <Routes></Routes>
    </div>
  )
}





