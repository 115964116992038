import swal from "sweetalert";
import axios from "axios";
import { API_URL } from "../constants";

export function signup(res, type, props) {
  const { history } = props;
  let postd;
  if (type === "facebook" && res) {
    postd = {
      name: res.name,
      provider: type,
      email: "",
      provider_id: res.id,
      token: res.accessToken,
      provider_pic: res.picture.data.url,
    };
  }

  if (type === "google" && res) {
    postd = {
      name: res.profileObj.name,
      provider: type,
      email: res.profileObj.email,
      provider_id: res.profileObj.googleId,
      token: res.accessToken,
      provider_pic: res.profileObj.imageUrl,
    };
  }

  if (postd) {
    axios({
      url: API_URL + "/sign.php",
      method: "post",
      data: postd,
      // headers:{'Access-Control-Allow-Origin': '*'}
    })
      .then(function (resu) {
        if (resu.data === "Login Successfully..🤩") {
          localStorage.setItem("email", postd.provider_id);
          if (localStorage.getItem("store")) {
            axios({
              method: "post",
              url: "./createresume.php",
              data: {
                auth: localStorage.getItem("email"),
                name: localStorage.getItem("name").split(","),
                socialmedia: localStorage.getItem("socialmedia").split(","),
                summary: localStorage.getItem("summary"),
                trainings: localStorage.getItem("trainings").split(","),
                placeholder: localStorage.getItem("placeholder").split(","),
                projectname: localStorage.getItem("projectname").split(","),
                projectdate: localStorage.getItem("projectdate").split(","),
                projectsum: localStorage.getItem("projectsum").split(","),
                shorttitle: localStorage.getItem("pshorttitle").split(","),
                skill: localStorage.getItem("percnt").split(","),
                percnt: localStorage.getItem("progress").split(","),
                role: localStorage.getItem("workexpr").split(","),
                companyname: localStorage.getItem("workexpr1").split(","),
                companylocation: localStorage.getItem("workexpr2").split(","),
                workdate: localStorage.getItem("workexpr4").split(","),
                worksum: localStorage.getItem("workexpr5").split(","),
                educationname1: localStorage.getItem("education").split(","),
                educationname2: localStorage.getItem("education1").split(","),
                educationdate: localStorage.getItem("education2").split(","),
                educationloc: localStorage.getItem("education4").split(","),
                educationcgpa: localStorage.getItem("education5").split(","),
                title: localStorage.getItem("title").split(","),
                langname: localStorage.getItem("langname").split(","),
                rating: localStorage.getItem("rating").split(","),
                level: localStorage.getItem("to").split(","),
                color: localStorage.getItem("color"),
                myTimeChartInformation:
                  JSON.parse(localStorage.getItem("myTimeChartInformation")) ||
                  [],
              },
              config: { headers: { "Access-Control-Allow-Origin": "*" } },
            })
              .then((response) => {})
              .catch(function (response) {});
          }
          swal("Login Succesfully", "", "success");
          if (localStorage.getItem("template") === "template1") {
            history.push("/resume");
          } else if (localStorage.getItem("template") === "template2") {
            history.push("/resume2");
          } else {
            history.push("/Sampleresume");
          }
        }
      })
      .catch(function (err) {});
  }
}

export function login(event, email, pwd) {
  event.preventDefault();
  let name = email;

  if (name.length === 0 && pwd.length === 0) {
    swal("Email and name are Required fields");
  } else if (name.length === 0) {
    swal("Required email");
  } else if (pwd.length === 0) {
    swal("Required password");
  } else {
    axios({
      url: API_URL + "/sign.php",
      method: "post",
      data: {
        useremail: email,
        userpwd: pwd,
      },
      // headers:{'Access-Control-Allow-Origin': '*'}
    })
      .then(function (res) {
        if (res.data === "Login Successfully..🤩") {
          localStorage.setItem("email", email);
          swal("Login Succesfully", "", "success");

          if (localStorage.getItem("store")) {
            axios({
              method: "post",
              url: API_URL + "/createresume.php",
              data: {
                auth: localStorage.getItem("email"),
                name: localStorage.getItem("name").split(","),
                socialmedia: localStorage.getItem("socialmedia").split(","),
                summary: localStorage.getItem("summary"),
                trainings: localStorage.getItem("trainings").split(","),
                placeholder: localStorage.getItem("placeholder").split(","),
                projectname: localStorage.getItem("projectname").split(","),
                projectdate: localStorage.getItem("projectdate").split(","),
                projectsum: localStorage.getItem("projectsum").split(","),
                shorttitle: localStorage.getItem("pshorttitle").split(","),
                skill: localStorage.getItem("percnt").split(","),
                percnt: localStorage.getItem("progress").split(","),
                role: localStorage.getItem("workexpr").split(","),
                companyname: localStorage.getItem("workexpr1").split(","),
                companylocation: localStorage.getItem("workexpr2").split(","),
                workdate: localStorage.getItem("workexpr4").split(","),
                worksum: localStorage.getItem("workexpr5").split(","),
                educationname1: localStorage.getItem("education").split(","),
                educationname2: localStorage.getItem("education1").split(","),
                educationdate: localStorage.getItem("education2").split(","),
                educationloc: localStorage.getItem("education4").split(","),
                educationcgpa: localStorage.getItem("education5").split(","),
                title: localStorage.getItem("title").split(","),
                langname: localStorage.getItem("langname").split(","),
                rating: localStorage.getItem("rating").split(","),
                level: localStorage.getItem("to").split(","),
                color: localStorage.getItem("color"),
                myTimeChartInformation:
                  JSON.parse(localStorage.getItem("myTimeChartInformation")) ||
                  [],
              },
              config: { headers: { "Access-Control-Allow-Origin": "*" } },
            })
              .then((response) => {})
              .catch(function (response) {});
          }
          window.location = "/";
        } else {
          swal("Email or Password is Wrong", "", "error");
          document.getElementById("frm").reset();
          localStorage.removeItem("email");
        }
      })
      .catch(function (err) {});
  }
}
