import axios from 'axios';
import swal from 'sweetalert';
import { API_URL } from '../constants';

export default function send(){
    let name=document.getElementById('name').value;
    let email=document.getElementById('email').value;
    let msg=document.getElementById('message').value;   
    let postd={name:name,email:email,msg:msg};    
    
    axios({ 
     url:API_URL + '/feedback.php',
     method:'post',
     data:postd,
     headers:{'Access-Control-Allow-Origin': '*'}  
   }).then(function (res){
  
       if(res.data === "send"){
         swal("Successfully Send Mail","", "success");                                         
       }
  
          }).catch(function (err){
    
   });
  }