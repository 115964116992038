import React, { Component } from 'react';

export default class Sampleresume extends Component {
  componentDidMount() {
    if (localStorage.getItem('email') == null) {
      document.getElementById('logout').style.display = "none";
      document.getElementById('pdf').style.display = "none";
      document.getElementById('updf').style.display = "none";
      document.getElementById('font').style.display = "none";
      document.getElementById('color').style.display = "none";
      document.getElementById('login').style.display = "block";
      document.getElementById('signup').style.display = "block";
      document.getElementById('home').style.display = "block";
    } else {
      document.getElementById('font').style.display = "block";
      document.getElementById('color').style.display = "block";
      document.getElementById('signup').style.display = "none";
      document.getElementById('logout').style.display = "block";
      document.getElementById('pdf').style.display = "block";
      document.getElementById('updf').style.display = "none";

      document.getElementById('home').style.display = "none";
    }
  }

  template1 = () => {
    localStorage.setItem('login', 'yes');

    localStorage.setItem('template', 'template1');
    window.location = '/';

  }
  template2 = () => {
    localStorage.setItem('login', 'yes');

    localStorage.setItem('template', 'template2');
    window.location = '/';
  }

  render() {
    return (
      <div>
        <section class="testimonial_part" style={{ marginTop: '8em' }}>
          <div class="container-fluid">
            <div class="row justify-content-center">
              <div class="col-xl-5">
                <div class="section_tittle text-center">
                  <h2>Sample CV</h2>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-12">
                <div >
                  <div class="testimonial_slider">
                    <div class="row">
                      <div class="col-xl-4 col-lg-6 col-xs-6 col-sm-6 col-md-4">
                        <div class="testimonial_slider_text">
                          <img src="/img/1.png" onClick={this.template1} alt="Resume" style={{ cursor: "pointer" }} />
                          <label className="choose" onClick={this.template1}>Choose Template</label>
                        </div>
                      </div>
                      <div class="col-xl-4 col-lg-6 col-xs-6 col-sm-6 col-md-4">
                        <div class="testimonial_slider_text">
                          <img src="/img/2.jpg" onClick={this.template2} alt="Resume" style={{ cursor: "pointer" }} />
                          <label className="choose1" onClick={this.template2} >Choose Template</label>
                        </div>
                      </div>

                      <div class="col-xl-4 col-lg-6 col-xs-6 col-sm-6 col-md-4">
                        <div class="testimonial_slider_text">
                          <img src="/img/3.jpg" alt="Resume" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

