import React, { useState } from "react";
import {
  HashRouter as Router,
  Switch,
  Route,
  NavLink,
  Link,
} from "react-router-dom";
import Footer from "../components/Footer";
import page1 from "../login";
import page2 from "../signup";
import feedback from "../feedback";
import resume from "../resume";
import resume2 from "../resume2";
import home from "../home";
import sampleresume from "../sampleresume";
import PasswordForgot from "../components/PasswordForgot";
import TermsofService from "../components/TermsofService";
import Privacy from "../components/Privacy";
import Dashboard from "../components/Dashboard";
import SavedResume from "../SavedResume";
import SavedResume1 from "../SavedResume1";

export default function Routes(props) {
  const [showSubNav, toggleSubNav] = useState(false);
  const isLoggedIn = !!localStorage.getItem("email");
  return (
    <>
      <Router>
        <header className="main_menu home_menu ">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-12">
                <nav className="navbar navbar-expand-lg navbar-light">
                  <a className="navbar-brand" href="/" style={{ width: "35%" }}>
                    <img
                      src="img/logo.png"
                      alt="logo"
                      style={{ width: "50%" }}
                    />
                  </a>
                  <button
                    className="navbar-toggler"
                    type="button"
                    data-toggle="collapse"
                    data-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                  >
                    <span className="navbar-toggler-icon"></span>
                  </button>
                  <div
                    className="collapse navbar-collapse main-menu-item justify-content-end"
                    id="navbarSupportedContent"
                    style={{ width: "75%" }}
                  >
                    <ul className="navbar-nav align-items-center">
                      <li
                        onClick={() => toggleSubNav(false)}
                        className="nav-item "
                      >
                        <a
                          className="nav-link"
                          id="home"
                          activeClassName="active"
                          href="/"
                        >
                          Home
                        </a>
                      </li>
                      <li
                        onClick={() => toggleSubNav(false)}
                        className="nav-item "
                      >
                        <NavLink
                          exact
                          className="nav-link"
                          id="sample"
                          to="/sampleresume"
                        >
                          Sample CV
                        </NavLink>
                      </li>
                      <li
                        onClick={() => toggleSubNav(false)}
                        className="nav-item"
                      >
                        <NavLink
                          onMouseOver={() => toggleSubNav(false)}
                          className="nav-link"
                          id="login"
                          to="/resume"
                        >
                          Get Started
                        </NavLink>
                        <div
                          id="sub-nav"
                          style={{
                            position: "absolute",
                            marginTop: "10px",
                            marginLeft: "35px",
                            display: !showSubNav ? "none" : "",
                          }}
                        >
                          <Link className="nav-link" id="color">
                            Color
                          </Link>
                          <Link className="nav-link" id="font">
                            Font
                          </Link>
                        </div>
                      </li>
                      <li
                        onClick={() => toggleSubNav(false)}
                        className="nav-item"
                      >
                        <NavLink className="nav-link" to="/feedback">
                          Feedback
                        </NavLink>
                      </li>
                      {isLoggedIn && (
                        <li
                          onClick={() => toggleSubNav(false)}
                          className="nav-item"
                        >
                          <NavLink className="nav-link" to="/dashboard">
                            Dashboard
                          </NavLink>
                        </li>
                      )}
                      {/* <li className="nav-item">
                                                <Link className="nav-link" id="color" >Color</Link>
                                            </li>
                                            <li className="nav-item">
                                                <Link className="nav-link" id="font">Font</Link>
                                            </li> */}
                      <li
                        onClick={() => toggleSubNav(false)}
                        className="nav-item"
                      >
                        <Link className="nav-link" to="/login" id="updf">
                          Export
                        </Link>
                        <Link
                          className="nav-link"
                          id="pdf"
                          to={{ pathname: "/resume", action: "generatePDF" }}
                        >
                          Export
                        </Link>
                      </li>
                      <li
                        onClick={() => toggleSubNav(false)}
                        className="nav-item"
                      >
                        <NavLink
                          className="btn_1"
                          id="logout"
                          onClick={() => localStorage.removeItem("email")}
                          to="/login"
                        >
                          Logout
                        </NavLink>
                      </li>

                      <li
                        onClick={() => toggleSubNav(false)}
                        className="d-none d-lg-block"
                      >
                        <NavLink className="btn_1" id="signup" to="/signup">
                          Login/Signup
                        </NavLink>
                      </li>
                    </ul>
                  </div>
                </nav>
              </div>
            </div>
          </div>
        </header>
        <Switch>
          <Route exact path="/" component={home} />
          <Route exact path="/index.html" component={home} />
          <Route path="/home" component={home} />
          <Route path="/login" component={page1} />
          <Route path="/signup" component={page2} />
          <Route path="/resume" component={resume} />
          <Route path="/resume2" component={resume2} />
          <Route path="/Feedback" component={feedback} />
          <Route path="/Sampleresume" component={sampleresume} />
          <Route path="/Termsofservice" component={TermsofService} />
          <Route path="/Privacy" component={Privacy} />
          <Route path="/password-forgot" component={PasswordForgot} />
          <Route path="/dashboard" component={Dashboard} />
          <Route path="/savedresume/:id" component={SavedResume} />
          <Route path="/savedresume1/:id" component={SavedResume1} />
        </Switch>
        <Footer></Footer>
      </Router>
    </>
  );
}
