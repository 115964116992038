import axios from 'axios';
import swal from 'sweetalert';
import { API_URL } from '../constants';

export function signup(event, uname, email, pwd, cpwd, props) {
    event.preventDefault();
    const { history } = props;
    if (uname !== "" && email !== "" && pwd !== "" && cpwd !== "") {
        axios({
            url: API_URL + '/sign.php',
            method: 'post',
            data: {
                username: uname,
                email: email,
                passwd: pwd,
                cpasswd: cpwd
            },
            headers: { 'Access-Control-Allow-Origin': '*' }
        }).then(function (res) {
            document.getElementById('frm').reset();
            history.push('/login/');
            swal("Register Succesfully", "", "success");
        }).catch(function (err) { });
    } else {
        let err = document.getElementById('sp');
        pwd === cpwd ? err.style.display = "none" : err.style.display = "block";
        swal("Required All Fields", "", "warning");
    }
}