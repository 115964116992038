// withRouter.js (HOC to inject history)
import React from "react"; // Import React
import { useParams, useHistory } from "react-router-dom";

const withRouter = (ResumeList) => {
  const Wrapper = (props) => {
    const history = useHistory();
    const params = useParams();

    return <ResumeList history={history} {...props} params={params} />;
  };

  return Wrapper;
};

export default withRouter;
