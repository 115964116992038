import React from "react";
import ContentEditable from "react-contenteditable";
import DraggablePieChart from "./DraggablePieChart";

class MyTimeline extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      myTimeChartInformation: props.myTimeChartInformation,
    };
    this.proportions = [];
    this.color = "#009688";
  }

  shouldComponentUpdate(prevProps) {
    if (
      prevProps.myTimeChartInformation !== null &&
      prevProps.myTimeChartInformation.length !==
        this.state.myTimeChartInformation.length
    ) {
      this.setState(
        {
          myTimeChartInformation: prevProps.myTimeChartInformation,
        },
        this.setupPieChart
      );

      return true;
    }

    if (prevProps.color != this.props.color) {
      this.color = prevProps.color;
      this.setupPieChart();
    }
    return false;
  }
  componentDidMount() {
    this.setupPieChart();
  }

  setupPieChart() {
    console.log(this.props.color);
    var colorCodes = [
      `${this.color}FF`,
      `${this.color}D9`,
      `${this.color}99`,
      `${this.color}4D`,
      `${this.color}33`,
    ];
    var proportions = [];
    this.state.myTimeChartInformation.forEach((row, index) => {
      proportions.push({
        proportion: row.proportion,
        format: {
          color: colorCodes[index],
          label: String.fromCharCode(65 + index),
        },
      });
    });
    var setup = {
      canvas: document.getElementById("pieChart"),
      radius: 0.9,
      collapsing: true,
      proportions: proportions,
      onchange: this.onPieChartChange.bind(this),
    };
    if (this.myTimeLineChart === undefined) {
      this.myTimeLineChart = new DraggablePieChart();
    }
    this.myTimeLineChart.drawChart(setup);
  }

  onPieChartChange() {
    var percentages = this.myTimeLineChart.getAllSliceSizePercentages();
    let myTimeChartInformation = [...this.state.myTimeChartInformation];
    percentages.forEach(function (percentage, index) {
      myTimeChartInformation[index]["proportion"] = percentage;
    });
    this.setState({
      myTimeChartInformation: myTimeChartInformation,
    });
    if (myTimeChartInformation.length > 0) {
      this.props.onChange(myTimeChartInformation);
    }
  }

  handleChange = (index) => (evt) => {
    let myTimeChartInformation = [...this.state.myTimeChartInformation];
    myTimeChartInformation[index]["skill"] = evt.target.value;
    this.setState({
      myTimeChartInformation: myTimeChartInformation,
    });

    if (myTimeChartInformation.length > 0) {
      this.props.onChange(myTimeChartInformation);
    }
  };

  handleBlur = (evt) => {
    this.forceUpdate();
  };

  render() {
    return (
      <div>
        {/* Please god let this project succeed */}
        <canvas id="pieChart" width="200" height="200">
          Your browser is too old!
        </canvas>
        <div id="my-time-labels">
          {this.state.myTimeChartInformation.map((row, index) => (
            <div class="mytimeline-item" key={`my-timeline-${index}`}>
              <div class="mytimeline-dot">
                {String.fromCharCode(65 + index)}
              </div>
              <ContentEditable
                suppressContentEditableWarning={true}
                onBlur={this.handleBlur}
                onChange={this.handleChange(index)}
                placeholder="Activity / Inititative"
                className={`mytimeline-text editable-field ${
                  row.skill.length <= 0 ? "placeholder" : ""
                }`}
                html={this.state.myTimeChartInformation[index].skill}
                disabled={false}
              />
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export default MyTimeline;
