import React, { Component } from 'react';
import {
    Link
} from "react-router-dom";
export default class Home extends Component {
    componentDidMount() {
        const { history } = this.props;
        if (localStorage.getItem('login') === 'yes') {
            if (localStorage.getItem('template') === 'template1') {
                history.push('/resume');
            }
            else if (localStorage.getItem('template') === 'template2') {
                history.push('/resume2');
            }
        }
        else if (localStorage.getItem('email') === null) {
            document.getElementById('logout').style.display = "none";
            document.getElementById('pdf').style.display = "none";
            document.getElementById('updf').style.display = "none";
            document.getElementById('font').style.display = "none";
            document.getElementById('color').style.display = "none";
            document.getElementById('login').style.display = "block";
            document.getElementById('signup').style.display = "block";
            document.getElementById('home').style.display = "block";
            //Event.preventDefault();
            //history.push("/resume1/login/");
        } else {
            if (localStorage.getItem('template') === 'template1') {
                history.push('/resume');
            }
            else if (localStorage.getItem('template') === 'template2') {
                history.push('/resume2');
            }
            //  history.push('/resume')
            document.getElementById('font').style.display = "block";
            document.getElementById('color').style.display = "block";
            document.getElementById('signup').style.display = "none";
            document.getElementById('logout').style.display = "block";
            document.getElementById('pdf').style.display = "block";
            document.getElementById('updf').style.display = "none";

            document.getElementById('home').style.display = "none";
        }
    }

    render() {
        return (
            <div style={{marginTop: '120px'}}>
                <h2 class="h2" style={{ color: '#159e91', textAlign: 'center', marginBottom: '1em' }}>CV For Free</h2>
                <section>
                    <div class="howto">

                        <div class="title-section">
                            <h2 class="h2">Online CV Builder</h2>
                            <p class="subtitle">Write a perfect CV in a few clicks with our templates.</p>
                        </div>

                        <div class="howto-steps">
                            <div class="howto-steps-item">
                                <div class="card">
                                    <div class="card-header" style={{ padding: 0 }}>
                                        <img src="./img/choice.jpg" alt="Choose an exclusive template" />
                                    </div>
                                    <div class="card-title">
                                        <span class="number">1</span>
                                        <p><strong>Choose an exclusive template</strong></p>
                                    </div>
                                    <div class="card-text">
                                        <p>
                                            ExpressCV offers a wide selection of CV templates that you can easily customise.
                            </p>
                                    </div>
                                </div>
                            </div>

                            <div class="howto-steps-item">
                                <div class="card">
                                    <div class="card-header" style={{ padding: 0 }}>
                                        <img src="./img/choice-edit.jpg" alt="Build your professional CV" />
                                    </div>
                                    <div class="card-title">
                                        <span class="number">2</span>
                                        <p><strong>Build your professional CV</strong></p>
                                    </div>
                                    <div class="card-text">
                                        <p>
                                            Edit your CV in just a few minutes with our online creation platform.
                            </p>
                                    </div>
                                </div>
                            </div>

                            <div class="howto-steps-item">
                                <div class="card">
                                    <div class="card-header" style={{ padding: 0 }}>
                                        <img src="./img/choice-download.jpg" alt="Download the perfect CV" />
                                    </div>
                                    <div class="card-title">
                                        <span class="number">3</span>
                                        <p><strong>Download the perfect CV</strong></p>
                                    </div>
                                    <div class="card-text">
                                        <p>
                                            Download your CV in PDF, in one click, and finally complete all of your projects
                            </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="howto-cta text-center">
                            <Link class="text-uppercase button button-contactForm btn_1" to="/login" id="term">Create my CV</Link>
                        </div>

                    </div>
                </section>

                <section class="testimonial_part">
                    <div class="row justify-content-center">
                        <div class="col-xl-5">
                            <div class="section_tittle text-center">
                                <h2>Write your professional cv online.</h2>
                            </div>
                        </div>
                    </div>
                    <div class="testimonial_slider">
                        <div id="owl-example" class="owl-carousel">
                            <div class="testimonial_slider_text"> <img src="./img/1.png" alt="Resume" />
                            </div>
                            <div class="testimonial_slider_text"> <img src="./img/2.jpg" alt="Resume" />
                            </div>
                            <div class="testimonial_slider_text"><img src="./img/3.jpg" alt="Resume" />
                            </div>




                        </div>

                    </div>
                </section>
                <section class="banner_part">
                    <div class="container">
                        <div class="row align-items-center">
                            <div class="col-lg-6 col-xl-6">
                                <div class="banner_text">
                                    <div class="banner_text_iner ">
                                        <h3><b>Make it Easy CV builder</b></h3>
                                        <ul class="tick-list m-16 white hide-mobile" style={{ width: '60%' }}>
                                            <li ><i class="fa fa-check white" style={{ color: '#139d90' }}></i> Unique & impressive CV in minutes</li>
                                            <li ><i class="fa fa-check white" style={{ color: '#139d90' }}></i>Download PDF for free</li>
                                            <li ><i class="fa fa-check white" style={{ color: '#139d90' }}></i> Live Update format</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}