import React from "react";
import axios from "axios";
import { Table, Button, Pagination } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import withRouter from "../withRouter";
import Skeleton from "react-loading-skeleton"; // Import Skeleton

class ResumeList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      resumeList: [],
      loading: true,
      error: null,
      auth: localStorage.getItem("email"),
      currentPage: 1,
      resumesPerPage: 10,
    };
  }

  componentDidMount() {
    this.fetchResumeList();
  }

  fetchResumeList() {
    const { auth } = this.state;
    axios({
      method: "post",
      url: "https://www.flexcvpro.com/resumelist.php",
      data: {
        auth: auth,
      },
    })
      .then((response) => {
        if (response.data.success) {
          this.setState({
            resumeList: response.data.listdetail,
            loading: false,
          });
        } else {
          this.setState({
            error: "Failed to fetch resume list",
            loading: false,
          });
        }
      })
      .catch((error) => {
        this.setState({
          error: "Error fetching resume list",
          loading: false,
        });
      });
  }

  handlePageChange = (pageNumber) => {
    this.setState({ currentPage: pageNumber });
  };

  handleViewResume = (resumeId) => {
    const { auth } = this.state;

    axios({
      method: "post",
      url: "https://www.flexcvpro.com/resumedetail.php",
      data: {
        auth: auth,
        resume_id: resumeId,
      },
    })
      .then((response) => {
        if (response.data.success) {
          const resumeDetail = response.data.listdetail[0];
          if (resumeDetail.resume_template === "template2") {
            this.props.history.push(`/savedresume/${resumeId}`);
          } else if (resumeDetail.resume_template === "template1") {
            this.props.history.push(`/savedresume1/${resumeId}`);
          }
        } else {
          console.error("Failed to fetch resume details");
        }
      })
      .catch((error) => {
        console.error("Error fetching resume details:", error);
      });
  };

  render() {
    const { resumeList, loading, error, currentPage, resumesPerPage } =
      this.state;

    if (loading) {
      return (
        <div className="container mt-4">
          <h2 className="mb-4 text-center">Resume List</h2>
          <Table striped bordered hover responsive>
            <thead>
              <tr>
                <th
                  style={{ backgroundColor: "#0C9A8D", color: "#fff" }}
                  className="text-center"
                >
                  S.No
                </th>
                <th
                  style={{ backgroundColor: "#0C9A8D", color: "#fff" }}
                  className="text-center"
                >
                  Resume Title
                </th>
                <th
                  style={{ backgroundColor: "#0C9A8D", color: "#fff" }}
                  className="text-center"
                >
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>
              {[...Array(resumesPerPage)].map((_, index) => (
                <tr key={index}>
                  <td className="text-center">
                    <Skeleton width={40} />
                  </td>
                  <td className="text-center">
                    <Skeleton width={200} />
                  </td>
                  <td className="text-center">
                    <Skeleton width={100} />
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      );
    }

    if (error) {
      return <p>{error}</p>;
    }

    const indexOfLastResume = currentPage * resumesPerPage;
    const indexOfFirstResume = indexOfLastResume - resumesPerPage;
    const currentResumes = resumeList.slice(
      indexOfFirstResume,
      indexOfLastResume
    );
    const totalPages = Math.ceil(resumeList.length / resumesPerPage);

    return (
      <div className="container mt-4">
        <section className="contact-section section_padding">
          <div className="container">
            <div className="row">
              <div className="col">
                <h2 className="mb-4 text-center">Resume List</h2>
                <Table striped bordered hover responsive>
                  <thead>
                    <tr>
                      <th
                        style={{ backgroundColor: "#0C9A8D", color: "#fff" }}
                        className="text-center"
                      >
                        S.No
                      </th>
                      <th
                        style={{ backgroundColor: "#0C9A8D", color: "#fff" }}
                        className="text-center"
                      >
                        Resume Title
                      </th>
                      <th
                        style={{ backgroundColor: "#0C9A8D", color: "#fff" }}
                        className="text-center"
                      >
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentResumes.map((resume, index) => (
                      <tr key={resume.resume_id}>
                        <td className="text-center">
                          {index + indexOfFirstResume + 1}
                        </td>
                        <td className="text-center">
                          {resume.resume_title || "No Title"}
                        </td>
                        <td className="text-center">
                          <Button
                            variant="outline-info"
                            size="sm"
                            style={{ borderColor: "#0C9A8D", color: "#0C9A8D" }}
                            onClick={() =>
                              this.handleViewResume(resume.resume_id)
                            }
                          >
                            <FontAwesomeIcon
                              icon={faEye}
                              style={{ color: "#0C9A8D" }}
                            />{" "}
                            View
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                <Pagination className="justify-content-end" size="md">
                  <Pagination.Prev
                    onClick={() => this.handlePageChange(currentPage - 1)}
                    disabled={currentPage === 1}
                  />
                  {[...Array(totalPages).keys()].map((pageNumber) => (
                    <Pagination.Item
                      key={pageNumber + 1}
                      active={pageNumber + 1 === currentPage}
                      onClick={() => this.handlePageChange(pageNumber + 1)}
                    >
                      {pageNumber + 1}
                    </Pagination.Item>
                  ))}
                  <Pagination.Next
                    onClick={() => this.handlePageChange(currentPage + 1)}
                    disabled={currentPage === totalPages}
                  />
                </Pagination>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default withRouter(ResumeList);
