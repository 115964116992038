import React, { useState, useEffect } from "react";
import GoogleLogin from "react-google-login";
import FacebookLogin from "react-facebook-login";
import "./static/register.css";
import { Link } from "react-router-dom";
import { login, signup } from "./helpers/login";

export default function Login(props) {
  const [email, updateEmail] = useState("");
  const [pwd, updatePwd] = useState("");

  useEffect(() => {
    const { history } = props;
    if (localStorage.getItem("email") == null) {
      document.getElementById("login").style.display = "block";
      document.getElementById("signup").style.display = "block";
      document.getElementById("color").style.display = "none";
      document.getElementById("logout").style.display = "none";
      document.getElementById("pdf").style.display = "none";
      document.getElementById("home").style.display = "block";
      document.getElementById("updf").style.display = "none";
    } else {
      if (localStorage.getItem("template") === "template1") {
        history.push("/resume");
      } else if (localStorage.getItem("template") === "template2") {
        history.push("/resume2");
      } else {
        history.push("/Sampleresume");
      }
      document.getElementById("color").style.display = "block";
      document.getElementById("login").style.display = "none";
      document.getElementById("signup").style.display = "none";
      document.getElementById("updf").style.display = "none";

      document.getElementById("logout").style.display = "block";
      document.getElementById("pdf").style.display = "block";
      document.getElementById("home").style.display = "none";
    }
  });

  const responseGoogle = (response) => {
    signup(response, "google");
  };

  const responseFacebook = (response) => {
    signup(response, "facebook", props);
  };

  return (
    <div className="mt" style={{ backgroundRepeat: "round" }}>
      <div className="container">
        <div className="row">
          <div className="col-md-4"></div>
          <div className="col-md-4 box">
            <h3 className="text-center">Login</h3>
            <form id="frm">
              <div className="form-group mb-4">
                <label>Email</label>
                <input
                  type="text"
                  onKeyUp={(e) => updateEmail(e.target.value)}
                  className="form-control"
                  placeholder="Enter Email"
                ></input>
              </div>
              <div className="form-group mb-4">
                <label>Password</label>
                <input
                  type="password"
                  onKeyUp={(e) => updatePwd(e.target.value)}
                  className="form-control"
                  placeholder="Enter Password"
                ></input>
              </div>
              {/* <lable style={{cursor:"pointer"}}  onClick={this.login.bind(this)}   className="btn btn-primary" >Login</lable> */}
              <button
                type="submit"
                onClick={(e) => login(e, email, pwd)}
                class="button button-contactForm btn_1"
              >
                Login
              </button>
              <Link
                to="/password-forgot"
                style={{
                  float: "right",
                  lineHeight: "2.5em",
                  color: "rgb(0, 144, 255)",
                }}
              >
                Forgot Your password?
              </Link>
              <div style={{ textAlign: "center", marginTop: "1em" }}>
                {/*<div class="text-center m-bottom-1"><a class="text-muted">Forgotten password?</a></div>*/}
                Don't have an account?&nbsp;
                <Link to="/signup">Create an account</Link>
              </div>
            </form>
            <br />
            {/* <hr></hr> */}
            {/* <div style={{ textAlign: "center" }} id="googlediv">
              <GoogleLogin
                // eslint-disable-next-line react/style-prop-object
                style="background-color:red"
                clientId="549653472297-knnju3edneg5840vbi2lkvr2p9ba2ved.apps.googleusercontent.com"
                buttonText="Login  with Google"
                onSuccess={responseGoogle}
                onFailure={responseGoogle}
                cookiePolicy={'single_host_origin'}

              />
            </div>
            <center><label></label></center>
            <div style={{ textAlign: "center" }} id="fbdiv">

              <FacebookLogin
                appId="1110428352636541"

                fields="name,email,picture"
                callback={responseFacebook}
                icon="fa-facebook"
              />
            </div> */}
          </div>
          <div className="col-md-1"></div>
        </div>
      </div>
    </div>
  );
}
